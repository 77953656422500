import React, { useEffect, useState } from "react";
import {
  DocotorIcon,
  DocumentIcon,
  PlusIcon,
} from "../../assets/common-svg/CommonSvg";
import { useLocation, useNavigate, useParams } from "react-router";
import Loadable from "../../components/Loadable";
import CustomDatePicker from "../../components/custom-date-picker/CustomDatePicker";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { EditPhase } from "../../utils/formik/initialValues";
import { addPhaseValidationSchema } from "../../utils/formik/validationSchema";
import { LoadingButton } from "@mui/lab";
import { setSnackbar } from "../../store/reducers/snackBar";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import moment from "moment";
import api from "../../services/request";
import CustomMultiSelectDropdown from '../../components/custom-multiselect-dropdown/MultiSelectDropdown';
import PhaseApi from "../../services/phase";
import SubAdminApi from "../../services/subAdminService";
// import { formTemplateDropdownList } from "../../utils/common";
import * as Yup from 'yup';

const CustomInputField = Loadable(
  React.lazy(() => import("../../components/custom-input/CustomInput"))
);
const TrialInfoComponent = Loadable(
  React.lazy(() =>
    import("../../components/trial-info-component/TrialInfoComponent")
  )
);
const CustomDropdown = Loadable(
  React.lazy(() => import("../../components/custom-dropdown/CustomDropdown"))
);
const CustomDetailComponent = Loadable(
  React.lazy(() =>
    import("../../components/doctor-detail-component/DocotorDetail")
  )
);
const AssignFormTemplateDrawerContent = Loadable(
  React.lazy(() =>
    import(
      "../../components/custom-drawer-contents/edit-trial-detils-drawer-contents/AssignFormTemplateContent"
    )
  )
);
const AssignTrialManagetDrawerContent = Loadable(
  React.lazy(() =>
    import(
      "../../components/custom-drawer-contents/edit-trial-detils-drawer-contents/AssignTrialManagetDrawerContent"
    )
  )
);
const AssignImmunoACTQADrawerContent = Loadable(
  React.lazy(() =>
    import(
      "../../components/custom-drawer-contents/edit-trial-detils-drawer-contents/AssignImmunoACTQADrawerContent"
    )
  )
);
const AssignPIDrawerContent = Loadable(
  React.lazy(() =>
    import(
      "../../components/custom-drawer-contents/edit-trial-detils-drawer-contents/AssignPIDrawerContent"
    )
  )
);
const DeleteConfirmContent = Loadable(
  React.lazy(() =>
    import("../../components/modal-contents/DeleteConfirmModalContent")
  )
);

const filterPhaseDetails = (phaseDetail) => {
  let filteredPiList =  phaseDetail?.investigator_details?.principal_investigator?.filter((data)=>{
    return data?.id !== phaseDetail?.investigator_details?.coordinating_pi?.id;
  });
  let updatedResponse = structuredClone(phaseDetail);
  updatedResponse.investigator_details.principal_investigator=filteredPiList;
  return updatedResponse;
}

export const EditTrialDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { pICoordinator, trialManager, iAQA, phaseType,} = useSelector(
    (state) => state?.subAdminDropdown
  );
  const { phaseDetail } = useSelector((state) => state?.phaseReducer);
  const [selectedTemplateList,setSelectedTemplateList] = useState(phaseDetail?.template_type_ids||[]);
  const [initialValues, setInitialValues] = useState(EditPhase);
  const [isUpdatePrLoading, setIsUpdatePrLoading] = useState(false);
  const [formTemplateList, setFormTemplateList] = useState([]);
  const [selectedAssignPI, setSelectedAssignPI] = useState("");
  const [assignPIList, setAssignPIList] = useState([]);
  const [assignPIListAll, setAssignPIListAll] = useState([]);
  const [immunoACTList, setImmunoACTList] = useState([]);
  const [trialManagerList, setTrialManagerList] = useState([]);
  const[updatedPhaseDetail,] = useState(filterPhaseDetails(phaseDetail))
  const [formTemplateLoading, setFormTemplateLoading] = useState(false);
  const [assignPILoading, setAssignPILoading] = useState(false);
  const [tempFormPIList, setTempFormPIList] = useState([]);
  const [selectedTempList, setSelectedTempList] = useState([]);
  const [selectedAssignPIList, setSelectedAssignPIList] = useState([]);
  const [selectedImmunoACTList, setSelectedImmunoACTList] = useState([]);
  const [selectedTrialList, setSelectedTrialList] = useState([]);
  const [deleteID, setDeleteID] = useState(null);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] =
    React.useState(false);
  const dispatch = useDispatch();

  const snackbar = useSelector((state) => state.snackBar);
  const [openDrawer, setOpenDrawer] = React.useState({
    openAssignTemplateDrawer: false,
    openAssignTrialManagetDrawer: false,
    openAssignImmunoACTQADrawer: false,
    openAssignPI: false,
  });
  const navigate = useNavigate();
  const [modelData, setModelData] = useState({
    title: "Edit Phase",
    children: [
      {
        type: "dropdown",
        name: "phase_type",
        label: "Phase Type",
        placeholder: "Select Phase",
        IsRequired: true,
      },
      {
        type: "input",
        name: "title",
        label: "Title",
        placeholder: "Enter Title",
        IsRequired: true,
      },

      {
        type: "date",
        name: "phase_start_date",
        label: "Phase Start Date",
        placeholder: "Select date",
        IsRequired: true,
      },
      {
        type: "number",
        name: "minimum_subjects",
        label: "Minimum no of Patients",
        placeholder: "Enter Number",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "coordinating_PI_id",
        label: "Assign Medical Expert",
        placeholder: "Select Assign Medical Expert",
        IsRequired: true,
      },
      // {
      //   type: "multidropdown",
      //   name: "template_type_ids",
      //   id: "template_type_ids",
      //   label: "Required Visit Types",
      //   data: formTemplateDropdownList,
      //   showSelectAll: true,
      //   placeholder: "Select Visit Types",
      //   IsRequired: true,
      //   disabled:true
      // },

      {
        type: "description",
        name: "description",
        label: "Description",
        placeholder: "Description",
        class: "!col-auto description-trial",
        IsRequired: true,
      },
    ],
  });
  useEffect(() => {
    if (modelData?.children) {
      const mapedSelectDrop = modelData.children.map((item) => {
        switch (item.name) {
          case "title":
            return { ...item, data: iAQA };
          case "qa_ids":
            return { ...item, data: iAQA };
          case "phase_start_date":
            return { ...item, data: pICoordinator };
          case "minimum_subjects":
            return { ...item, data: pICoordinator };
          case "coordinating_PI_id":
            return { ...item, data: pICoordinator };
          case "manager_ids":
            return { ...item, data: trialManager };
          case "phase_type":
            return { ...item, data: phaseType };
          default:
            return item;
        }
      });
 
      const updatedVisitNumber =  updatedPhaseDetail?.template_type_array?.map((item) => ({
        type: "number",
        name: `${item.id}`, // You can dynamically assign a unique name
        label: `${item.name}`, // Label with type name
        placeholder: "Enter Number",
        IsRequired: true,
        minValueNumber: 1
      }));

      setModelData((prevModelData) => ({
        ...prevModelData,
        children: [
          ...mapedSelectDrop.slice(0, -1), // All except the last item
          ...updatedVisitNumber,
          ...mapedSelectDrop.slice(-1) // Add the last item back
        ],
      }));

      setSelectedAssignPI(
        updatedPhaseDetail?.investigator_details?.coordinating_pi?.id || ""
      );
    }
  }, [iAQA, trialManager, pICoordinator, updatedPhaseDetail]);

  // Function to generate dynamic validation schema
const addVisitTypeNumValidationSchema = (dynamicFields) => {
  const dynamicSchema = {};

  // Generate schema for dynamic fields if they exist
  if (dynamicFields?.length) {
      dynamicFields.forEach((field) => {
          dynamicSchema[field.id] = Yup.number()
              .required(`${field.name} is required`)
              .min(1, `${field.name} must be greater than 0`) // Ensure value is greater than 1
              .integer(`${field.name} must be an integer`);
      });
  }

  // Combine the static schema (addPhaseValidationSchema) and dynamic schema
  return addPhaseValidationSchema.concat(Yup.object().shape(dynamicSchema));
};

  // setAddPhaseValidationSchemaUpdated
  useEffect(() => {
    setTempFormPIList({
      investigator_ids:
        updatedPhaseDetail?.investigator_details?.principal_investigator || [],
      form_template_ids: updatedPhaseDetail?.form_details || [],
      qa_ids: updatedPhaseDetail?.immunoactQA_details || [],
      manager_ids: updatedPhaseDetail?.trial_manager_details || [],
    });
    const visitIntialVal = {};

      updatedPhaseDetail?.template_type_array?.forEach(item => {
      visitIntialVal[item.id.toString()] = item.count
    });
    setInitialValues({
      title: updatedPhaseDetail?.title || "",
      phase_type: updatedPhaseDetail?.phase_type_details?.id || "",
      phase_start_date: updatedPhaseDetail?.phase_start_date || "",
      minimum_subjects: updatedPhaseDetail?.minimum_subjects || "",
      template_type_ids: updatedPhaseDetail?.template_type_ids||selectedTemplateList,
      coordinating_PI_id:
        updatedPhaseDetail?.investigator_details?.coordinating_pi?.id || "",
        description: updatedPhaseDetail?.description||"",
        ...visitIntialVal
    });
  }, [updatedPhaseDetail]);

  const handleUpdateTrail = async (values) => {
    // const normalizeArrayField = (field) => (field ? [field] : []);
    const removed_coordinating_PI_id = updatedPhaseDetail?.investigator_details?.coordinating_pi?.id !== values?.coordinating_PI_id ? updatedPhaseDetail?.investigator_details?.coordinating_pi?.id : 0



    const uniqueIdsFormTemp = getUniqueIds(tempFormPIList?.form_template_ids);
    const uniqueIdsAssignPI = getUniqueIds(tempFormPIList?.investigator_ids);
    const uniqueIdsImQa = getUniqueIds(tempFormPIList?.qa_ids);
    const updatedManager = getUniqueIds(tempFormPIList?.manager_ids);

    //code to find removed PI_Ids
    const PI_Ids = updatedPhaseDetail?.investigator_details?.principal_investigator?.map(item => item.id);
    const missing_pi_ids = PI_Ids?.filter(id => !uniqueIdsAssignPI.includes(id));

    //code to find removed QA_Ids
    const QA_Ids = updatedPhaseDetail?.immunoactQA_details?.map(item => item.id);
    const missing_qa_ids = QA_Ids?.filter(id => !uniqueIdsImQa.includes(id));

    //code to find removed phase_Manager_Ids
    const PM_Ids = updatedPhaseDetail?.trial_manager_details?.map(item => item.id);
    const missing_pm_ids = PM_Ids?.filter(id => !updatedManager.includes(id));

    //code to find removed form_template_ids
    const FT_Ids = updatedPhaseDetail?.form_details?.map(item => item.id);
    const missing_ft_ids = FT_Ids?.filter(id => !uniqueIdsFormTemp.includes(id));

    const updatedValues = {
      ...values,
      // qa_ids: normalizeArrayField(values.qa_ids),

      manager_ids: updatedManager || [],
      phase_start_date: moment(values.phase_start_date).format("MM/DD/YYYY"),
      product_id: updatedPhaseDetail?.product_details?.id,
      status: 1,
      form_template_ids: uniqueIdsFormTemp || [],
      investigator_ids: uniqueIdsAssignPI || [],
      phase_id: +id || "",
      qa_ids: uniqueIdsImQa || [],
      removed_coordinating_PI_id: removed_coordinating_PI_id,
      removed_investigator_ids: missing_pi_ids,
      removed_qa_ids: missing_qa_ids,
      removed_manager_ids: missing_pm_ids,
      removed_form_template_ids: missing_ft_ids
    };

    try {
      setIsUpdatePrLoading(true);

      // Extract the keys from template_type_ids and map them
      const templateTypeIdsMap = updatedValues.template_type_ids.reduce((acc, id) => {
        if (updatedValues[id]) {
            acc[id] = updatedValues[id];
        }
        return acc;
      }, {});

      // Create the new output object with template_type_ids replaced
      const outputData = {
        ...updatedValues,
        template_type_ids: templateTypeIdsMap
      };

      // Dynamically delete the keys that are included in template_type_ids
      updatedValues.template_type_ids.forEach(id => {
        delete outputData[id];
      });
      // API call to update phase
      const response = await PhaseApi.updatePhase(outputData, id);
      const UpdatedNavigationArray = location?.state;
      UpdatedNavigationArray?.pop();
      navigate(`/products/product-details/${updatedPhaseDetail?.product_details?.id}`, {
        state: UpdatedNavigationArray,
      });

      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Phase added successfully",
          color: "success",
        })
      );
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error.message || error || "Failed to add phase",
          color: "error",
        })
      );
    } finally {
      setIsUpdatePrLoading(false); // Reset loading state
    }
  };

  const toggleDrawer = (newOpen, DrawerContentName) => {
    if (DrawerContentName) {
      switch (DrawerContentName.toLowerCase()) {
        case "assigntemplatedrawer": {
          setOpenDrawer((prev) => ({
            ...prev,
            openAssignTemplateDrawer: newOpen,
          }));
          break;
        }
        case "assigntrialmanagetdrawer": {
          setOpenDrawer((prev) => ({
            ...prev,
            openAssignTrialManagetDrawer: newOpen,
          }));
          break;
        }
        case "assignimmunoactqadrawer": {
          setOpenDrawer((prev) => ({
            ...prev,
            openAssignImmunoACTQADrawer: newOpen,
          }));
          break;
        }
        case "assignpidrawer": {
          setOpenDrawer((prev) => ({ ...prev, openAssignPI: newOpen }));
          break;
        }
      }
    }
    return;
  };
  const getFormTemplateDropDown = async (
    form_template_ids,
    searchValue = ""
  ) => {
    setFormTemplateLoading(true);
    try {
      const res = await api.formTemplateDropDown(
        {
          // form_template_ids: form_template_ids, // If id's req
          form_template_ids: [],
          template_type_ids: phaseDetail?.template_type_ids || []
        },
        searchValue
      );
      setFormTemplateList(res?.data || []);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setFormTemplateLoading(false);
    }
  };

  useEffect(() => {
    setSelectedTempList(tempFormPIList?.form_template_ids || []);
  }, [tempFormPIList?.form_template_ids || []]);

  const getAssignAPIDropDown = async (
    investigator_ids,
    serachValue = "",
    moduleId,
    moduleName
  ) => {
    setAssignPILoading(true);

    try {
      const res = await SubAdminApi.getSubAdminDropdown(
        moduleId,
        {
          ids:
            moduleName === "assignPIDrawer"
              ? [...investigator_ids, selectedAssignPI]
              : investigator_ids || [],
        },
        serachValue
      );
      if (moduleName === "assignPIDrawer") {
        setAssignPIList(res?.data || []);
      } else if (moduleName === "assignImmunoACTQADrawer") {
        setImmunoACTList(res?.data || []);
      } else if (moduleName === "trialManagetDrawer") {
        setTrialManagerList(res?.data || []);
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setAssignPILoading(false);
    }
  };

  useEffect(() => {
    const fetchAssignPIList = async () => {
      try {
        const res = await SubAdminApi.getSubAdminDropdown(2, {
          ids: [],
        });
        setAssignPIListAll(res?.data || []);
      } catch (error) {
        dispatch(setSnackbar({ value: true, message: error, color: "error" }));
      }
    };

    fetchAssignPIList();
  }, []);

  const handleDeleteModal = (obj) => {
    setDeleteID(obj);
    setOpenDeleteConfirmModal(true);
  };

  const handleDelete = async () => {
    const { name, id } = deleteID;
    const keyMapping = {
      formTemplate: "form_template_ids",
      investigatorIds: "investigator_ids",
      immunoQaIds: "qa_ids",
      trialMonagerIds: "manager_ids",
    };

    if (keyMapping[name]) {
      const filteredValue = tempFormPIList?.[keyMapping[name]]?.filter(
        (item) => item.id !== id
      );
      setTempFormPIList((prev) => ({
        ...prev,
        [keyMapping[name]]: filteredValue,
      }));
    }
    setOpenDeleteConfirmModal(false);
  };

  const handleTempCheckboxChange = (e) => {
    const { id, name, checked } = e.target;
    setSelectedTempList((prevList) => {
      if (checked) {
        return [
          ...prevList,
          { id, title: name, type: e.target.getAttribute("data-attribute") },
        ];
      } else {
        return prevList.filter((item) => Number(item.id) !== Number(id));
      }
    });
  };

  const handleAssignPICheckboxChange = (e) => {
    const { id, name, checked } = e.target;
    setSelectedAssignPIList((prevList) => {
      if (checked) {
        return [...prevList, { id, title: name }];
      } else {
        return prevList.filter((item) => item.id !== id);
      }
    });
  };
  // AssignImmunoACTQADrawer
  const handleImmunoACTQACheckboxChange = (e) => {
    const { id, name, checked } = e.target;
    setSelectedImmunoACTList((prevList) => {
      if (checked) {
        return [...prevList, { id, title: name }];
      } else {
        return prevList.filter((item) => item.id !== id);
      }
    });
  };

  const handleTrailCheckboxChange = (e) => {
    const { id, name, checked } = e.target;
    setSelectedTrialList((prevList) => {
      if (checked) {
        return [...prevList, { id, title: name }];
      } else {
        return prevList.filter((item) => item.id !== id);
      }
    });
  };

  const handleFromTemp = () => {
    setTempFormPIList((prev) => {
      // Extract existing IDs for form templates and investigators
      // const existingFormTemplateIds = new Set(
      //   prev?.form_template_ids?.map((item) => item?.id)
      // );
      const existingInvestigatorIds = new Set(
        prev?.investigator_ids?.map((item) => item?.id)
      );
      const existingQaIds = new Set(prev?.qa_ids?.map((item) => item?.id));
      const existingManagerIds = new Set(
        prev?.manager_ids?.map((item) => item?.id)
      );

      // Filter new templates and investigators based on selected lists
      // const newFormTemplates = selectedTempList.filter(
      //   (template) => !existingFormTemplateIds.has(template.id)
      // );
      const newInvestigators = selectedAssignPIList.filter(
        (investigator) => !existingInvestigatorIds.has(investigator.id)
      );
      const newQa = selectedImmunoACTList.filter(
        (qa) => !existingQaIds.has(qa.id)
      );
      const newQaManager = selectedTrialList.filter(
        (item) => !existingManagerIds.has(item.id)
      );
      // Merge new templates and investigators with existing ones
      const updatedFormTemplates = [
        // ...(prev.form_template_ids || []),
        // ...newFormTemplates,
        ...selectedTempList
      ];
      const updatedInvestigators = [
        ...(prev.investigator_ids || []),
        ...newInvestigators,
      ];
      const updatedImQa = [...(prev.qa_ids || []), ...newQa];
      const updatedQaManager = [...(prev.manager_ids || []), ...newQaManager];
      return {
        ...prev,
        form_template_ids: updatedFormTemplates,
        investigator_ids: updatedInvestigators,
        qa_ids: updatedImQa,
        manager_ids: updatedQaManager,
      };
    });
    toggleDrawer(false, "AssignTemplateDrawer");
    toggleDrawer(false, "AssignPIDrawer");
    toggleDrawer(false, "AssignImmunoACTQADrawer");
    toggleDrawer(false, "AssignTrialManagetDrawer");
    setSelectedTempList([]);
    setSelectedAssignPIList([]);
    setSelectedImmunoACTList([]);
    setSelectedTrialList([]);
  };

  const getUniqueIds = (data) => {
    if (!data) {
      return [];
    }

    const uniqueIds = [...new Set(data?.map((item) => item?.id))];

    return uniqueIds;
  };

  const handleSearch = (value, type) => {
    const searchTypeMapping = {
      formTemplate: {
        ids: tempFormPIList?.form_template_ids,
        searchFunction: getFormTemplateDropDown,
      },
      assignPIDrawer: {
        ids: tempFormPIList?.investigator_ids,
        searchFunction: getAssignAPIDropDown,
      },
      assignImmunoACTQADrawer: {
        ids: tempFormPIList?.qa_ids,
        searchFunction: getAssignAPIDropDown,
      },
      trialManagetDrawer: {
        ids: tempFormPIList?.manager_ids,
        searchFunction: getAssignAPIDropDown,
      },
    };

    const selectedType = searchTypeMapping[type];
    if (selectedType) {
      const uniqueIds = getUniqueIds(selectedType.ids);
      if (type === "formTemplate") {
        selectedType.searchFunction(uniqueIds, value);
      } else {
        const moduleId =
          type === "assignPIDrawer"
            ? 2
            : type === "assignImmunoACTQADrawer"
            ? 3
            : 4;
        selectedType.searchFunction(uniqueIds, value, moduleId, type);
      }
    }
  };

  const handleTemplateList = (data)=>{
    setSelectedTemplateList(data);
  }



  return (
    <div className="flex flex-col p-3 gap-3">
      <div className="mb-3">
        <BreadCrumb
          BreadCrumbsListing={[{ name: "Products", path: "/products" }, { name: `${updatedPhaseDetail?.product_details?.name || ""}Details`, path: `/products/product-details/${updatedPhaseDetail?.product_details?.id}` }, { name: "Edit Phase Details", path: location.pathname }]}
        />
      </div>
      <div className="relative  pb-5 mb-5">
        <TrialInfoComponent
          ShowHeaderButton={false}
          HeaderTitle={"General Information"}
          ButtonText={"Add Template"}
        >
          <div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={()  => addVisitTypeNumValidationSchema(updatedPhaseDetail.template_type_array)}
              onSubmit={(values) => {
                handleUpdateTrail(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="p-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {modelData?.children?.map((child, index) => {
                       if (child?.type == "description") {
                        return (
                          <div className={`edit-column-input col-span-full ${child?.class}`} key={child?.name}>
                            <CustomInputField
                              key={index}
                              htmlFor={child?.name}
                              label={child?.label}
                              IsRequired={child?.IsRequired}
                              fullWidthBoolean={true}
                              InputId={child?.name}
                              InputType="text"
                              spacing={1}
                              MultiLine={true}
                              autoSize={true}
                              NoOfRows={5}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputName={child?.name}
                              
                              CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                              placeholder={child?.placeholder}
                              LabelClasses={"text-[16px] text-[#000000]"}
                              error={Boolean(
                                touched[child?.name] && errors[child?.name]
                              )}
                              touched={touched[child?.name]}
                              helperText={errors[child?.name]}
                              value={
                                values[child?.name] ? values[child?.name] : ""
                              }
                              disabled={child?.disabled}
                            />
                          </div>
                        );
                      }
                      if (child?.type == "input") {
                        return (
                          <div className="edit-column-input" key={child?.name}>
                            <CustomInputField
                              key={index}
                              htmlFor={child?.name}
                              label={child?.label}
                              IsRequired={child?.IsRequired}
                              fullWidthBoolean={true}
                              InputId={child?.name}
                              InputType="text"
                              spacing={1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputName={child?.name}
                              CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                              placeholder={child?.placeholder}
                              LabelClasses={"text-[16px] text-[#000000]"}
                              error={Boolean(
                                touched[child?.name] && errors[child?.name]
                              )}
                              touched={touched[child?.name]}
                              helperText={errors[child?.name]}
                              value={
                                values[child?.name] ? values[child?.name] : ""
                              }
                              disabled={child?.disabled}
                            />
                          </div>
                        );
                      }
                      if (child?.type == "number") {
                        return (
                          <div className="edit-column-input" key={child?.name}>
                            <CustomInputField
                              key={index}
                              htmlFor={child?.name}
                              label={child?.label}
                              IsRequired={child?.IsRequired}
                              fullWidthBoolean={true}
                              InputId={child?.name}
                              InputType="number"
                              minValueNumber={child?.minValueNumber || 0}
                              spacing={1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputName={child?.name}
                              CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                              placeholder={child?.placeholder}
                              LabelClasses={"text-[16px] text-[#000000]"}
                              error={Boolean(
                                touched[child?.name] && errors[child?.name]
                              )}
                              touched={touched[child?.name]}
                              helperText={errors[child?.name]}
                              value={values[child.name]}
                            />
                          </div>
                        );
                      }
                      if (child?.type == "date") {
                        return (
                          <div className="edit-column-input" key={child?.name}>
                            <CustomDatePicker
                              key={index}
                              LabelText={child?.label}
                              BgColor={"#EDEDED"}
                              onBlur={handleBlur}
                              handleChange={(date) => {
                                setFieldValue(child?.name, date);
                              }}
                              htmlFor={child?.name}
                              FontSize={"15px"}
                              IsRequired={child?.IsRequired}
                              PaddingY={"2px"}
                              InputId={child?.name}
                              InputName={child?.name}
                              placeholder={child?.placeholder}
                              error={Boolean(
                                touched[child?.name] && errors[child?.name]
                              )}
                              touched={touched[child?.name]}
                              helperText={errors[child?.name]}
                              value={values[child?.name]}
                              minDate={updatedPhaseDetail?.phase_start_date}
                            />
                          </div>
                        );
                      }
                      if (child?.type == "dropdown") {
                        return (
                          <div className="edit-column-input" key={child?.name}>
                            <CustomDropdown
                              key={index}
                              IsRequired={child?.IsRequired}
                              DropDownlabel={child?.label}
                              fullWidthBoolean={true}
                              ShowLargeDropdown={true}
                              BgColor={"#EDEDED"}
                              PlaceholderText={child?.placeholder}
                              disabled={child?.disabled}
                              onBlur={handleBlur}
                              handleChange={(e) => {
                                handleChange({
                                  target: {
                                    name: child?.name,
                                    value: e.target.value,
                                    type: "select",
                                  },
                                });
                                if (child?.name === "coordinating_PI_id") {
                                  setSelectedAssignPI(Number(e.target.value));
                                  setTempFormPIList({
                                    ...tempFormPIList,
                                    investigator_ids:
                                      tempFormPIList?.investigator_ids.filter(
                                        (item) =>
                                          Number(item.id) !==
                                          Number(e.target.value)
                                      ) || [],
                                  });
                                }
                              }}
                              error={Boolean(
                                touched[child?.name] && errors[child?.name]
                              )}
                              touched={touched[child?.name]}
                              helperText={errors[child?.name]}
                              MenuList={child.data}
                              value={values[child.name]}
                            />
                          </div>
                        );
                      }

                      if (child?.type == 'multidropdown') {
                        return <CustomMultiSelectDropdown
                            key={index}
                            IsRequired={child?.IsRequired}
                            DropDownlabel={child?.label}
                            fullWidthBoolean={true}
                            ShowLargeDropdown={true}
                            PlaceholderText={child?.placeholder}
                            disabled={child?.disabled}
                            onBlur={handleBlur}
                            handleChange={(selectedValues) => {
                                handleChange({
                                    target: {
                                        name: child?.name,
                                        value: selectedValues,
                                        type: "select"
                                    }
                                });
                                if(child?.name === "formId"){
                                    setFieldValue('questionId', [])
                                }
                                handleTemplateList(selectedValues, child?.name)
                            }}
                            error={Boolean(touched[child?.name] && errors[child?.name])}
                            touched={touched[child?.name]}
                            helperText={errors[child?.name]}
                            MenuList={child.data}
                            value={Array.isArray(values[child.name]) ? values[child.name] : []} // Ensure value is array
                            multiple={true} // Enable multi-select
                            showSelectAll={child?.showSelectAll}
                            grouping = {child?.name === "questionId"}
                        />



                    }

                    })}
                  </div>
                  <TrialInfoComponent
                    ShowHeaderButton={true}
                    HeaderTitle={"Attached Form Builder"}
                    ButtonText={"Form Builder"}
                    HeaderButtonIcon={<PlusIcon />}
                    HandleButton={() => {
                      const uniqueIds = getUniqueIds(
                        tempFormPIList?.form_template_ids
                      );
                      getFormTemplateDropDown(uniqueIds);
                      toggleDrawer(true, "AssignTemplateDrawer");
                    }}
                  >
                    <div className="flex gap-3 justify-start flex-wrap">
                      {tempFormPIList?.form_template_ids?.length > 0
                        ? tempFormPIList?.form_template_ids?.map((item) => (
                            <CustomDetailComponent
                              handleDelete={() =>
                                handleDeleteModal({
                                  id: item.id,
                                  name: "formTemplate",
                                })
                              }
                              setOpenDeleteConfirmModal={
                                setOpenDeleteConfirmModal
                              }
                              FirstHeading={item.title}
                              Icon={<DocumentIcon />}
                              ShowDeleteIcon={true}
                              key={item.id}
                            />
                          ))
                        : "No Form Builder Found."}
                    </div>
                  </TrialInfoComponent>
                  <TrialInfoComponent
                    HandleButton={() => {
                      // setSelectedTempList([])
                      setSelectedAssignPIList([]);
                      setSelectedImmunoACTList([])
                      setSelectedTrialList([])
                      const uniqueIds = getUniqueIds(
                        tempFormPIList?.investigator_ids
                      );
                      getAssignAPIDropDown(uniqueIds, "", 2, "assignPIDrawer");
                      toggleDrawer(true, "AssignPIDrawer");
                    }}
                    ShowHeaderButton={true}
                    HeaderTitle={"Assign PI"}
                    ButtonText={"Assign PI"}
                    HeaderButtonIcon={<PlusIcon />}
                  >
                    <div className="flex gap-3 justify-start flex-wrap">
                      {tempFormPIList?.investigator_ids?.length > 0
                        ? tempFormPIList?.investigator_ids?.map((item) => {
                            const matchedTemplate = assignPIListAll.find(
                              (template) =>
                                Number(template.id) === Number(item.id)
                            );

                            return (
                              <CustomDetailComponent
                                handleDelete={() =>
                                  handleDeleteModal({
                                    id: item.id,
                                    name: "investigatorIds",
                                  })
                                }
                                // LimitSizeBoolean={true}
                                // FlexGrow={true}
                                FirstHeading={item.title || item.name}
                                SecondHeading={
                                  item?.hospital_details?.name ||
                                  matchedTemplate?.hospital_details?.name
                                }
                                Icon={<DocotorIcon />}
                                ShowDeleteIcon={true}
                                key={item.id}
                              />
                            );
                          })
                        : "No Assign PI Found."}
                    </div>
                  </TrialInfoComponent>
                  <TrialInfoComponent
                    HandleButton={() => {
                      setSelectedImmunoACTList([]);
                      setSelectedAssignPIList([]);
                      setSelectedTrialList([])
                      const uniqueIds = getUniqueIds(tempFormPIList?.qa_ids);
                      getAssignAPIDropDown(
                        uniqueIds,
                        "",
                        3,
                        "assignImmunoACTQADrawer"
                      );
                      toggleDrawer(true, "AssignImmunoACTQADrawer");
                    }}
                    ShowHeaderButton={true}
                    HeaderTitle={"Quality Associate"}
                    ButtonText={"Quality Associate"}
                    HeaderButtonIcon={<PlusIcon />}
                  >
                    <div className="flex gap-3 justify-start flex-wrap">
                      {tempFormPIList?.qa_ids?.length > 0
                        ? tempFormPIList?.qa_ids?.map((item) => {
                            return (
                              <CustomDetailComponent
                                handleDelete={() =>
                                  handleDeleteModal({
                                    id: item.id,
                                    name: "immunoQaIds",
                                  })
                                }
                                // LimitSizeBoolean={true}
                                // FlexGrow={true}
                                FirstHeading={item.title || item.name}
                                SecondHeading={
                                  item?.hospital_details?.name || ""
                                }
                                Icon={<DocotorIcon />}
                                ShowDeleteIcon={true}
                                key={item.id}
                              />
                            );
                          })
                        : "No Quality Associate Found."}
                    </div>
                  </TrialInfoComponent>
                  <TrialInfoComponent
                    HandleButton={() => {
                      setSelectedTrialList([]);
                      setSelectedImmunoACTList([]);
                      setSelectedAssignPIList([]);
                      const uniqueIds = getUniqueIds(
                        tempFormPIList?.manager_ids
                      );
                      getAssignAPIDropDown(
                        uniqueIds,
                        "",
                        4,
                        "trialManagetDrawer"
                      );
                      toggleDrawer(true, "AssignTrialManagetDrawer");
                    }}
                    ShowHeaderButton={true}
                    HeaderTitle={"Clinical Phase Manager"}
                    ButtonText={"Clinical Phase Manager"}
                    HeaderButtonIcon={<PlusIcon />}
                  >
                    <div className="flex gap-3 justify-start flex-wrap">
                      {tempFormPIList?.manager_ids?.length > 0
                        ? tempFormPIList?.manager_ids?.map((item) => {
                            return (
                              <CustomDetailComponent
                                handleDelete={() =>
                                  handleDeleteModal({
                                    id: item.id,
                                    name: "trialMonagerIds",
                                  })
                                }
                                // LimitSizeBoolean={true}
                                // FlexGrow={true}
                                FirstHeading={item.title || item.name}
                                SecondHeading={
                                  item?.hospital_details?.name || ""
                                }
                                Icon={<DocotorIcon />}
                                ShowDeleteIcon={true}
                                key={item.id}
                              />
                            );
                          })
                        : "No Clinical Phase Manager Found."}
                    </div>
                  </TrialInfoComponent>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    size="large"
                    onSubmit={handleSubmit}
                    className="update-trial-btn !pl-3 !pr-3"
                    disabled={isUpdatePrLoading}
                    loading={isUpdatePrLoading}
                  >
                    Update
                  </LoadingButton>
                </form>
              )}
            </Formik>
          </div>
        </TrialInfoComponent>
      </div>
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
      <AssignFormTemplateDrawerContent
        toggleDrawer={() => toggleDrawer(false, "AssignTemplateDrawer")}
        open={openDrawer.openAssignTemplateDrawer}
        formTemplateList={formTemplateList}
        handleCheckboxChange={handleTempCheckboxChange}
        handleAssignTemp={handleFromTemp}
        disabledBtn={selectedTempList?.length > 0 ? false : true}
        loading={formTemplateLoading}
        handleSearch={(e) => handleSearch(e, "formTemplate")}
        checked={selectedTempList}
      />
      <AssignPIDrawerContent
        toggleDrawer={() => toggleDrawer(false, "AssignPIDrawer")}
        open={openDrawer.openAssignPI}
        assignPIList={assignPIList}
        checked={selectedAssignPIList}
        handleCheckboxChange={handleAssignPICheckboxChange}
        handleAssignTemp={handleFromTemp}
        disabledBtn={selectedAssignPIList?.length > 0 ? false : true}
        loading={assignPILoading}
        handleSearch={(e) => handleSearch(e, "assignPIDrawer")}
      />

      <AssignImmunoACTQADrawerContent
        toggleDrawer={() => toggleDrawer(false, "AssignImmunoACTQADrawer")}
        open={openDrawer.openAssignImmunoACTQADrawer}
        immunoACTList={immunoACTList}
        checked={selectedImmunoACTList}
        handleCheckboxChange={handleImmunoACTQACheckboxChange}
        handleAssignTemp={handleFromTemp}
        disabledBtn={selectedImmunoACTList?.length > 0 ? false : true}
        loading={assignPILoading}
        handleSearch={(e) => handleSearch(e, "assignImmunoACTQADrawer")}
      />
      <AssignTrialManagetDrawerContent
        toggleDrawer={() => toggleDrawer(false, "AssignTrialManagetDrawer")}
        open={openDrawer.openAssignTrialManagetDrawer}
        trialManagerList={trialManagerList}
        checked={selectedTrialList}
        handleCheckboxChange={handleTrailCheckboxChange}
        handleAssignTemp={handleFromTemp}
        disabledBtn={selectedTrialList?.length > 0 ? false : true}
        loading={assignPILoading}
        handleSearch={(e) => handleSearch(e, "trialManagetDrawer")}
      />
      <DeleteConfirmContent
        open={openDeleteConfirmModal}
        setOpen={setOpenDeleteConfirmModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};
