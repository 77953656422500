import { Typography } from "@mui/material";
import {
  PersonSvg,
  InvestigatorIcon,
  SubjectButton,
} from "../../../assets/common-svg/CommonSvg";
import { CustomProductDetailCard } from "../custom-product-details-card/CustomProductDetailCard";
import { CustomProgressCard } from "../custom-progress-card/CustomProgressCard";

import SkeletonLoader from "../../../utilites/SkeltonLoader";
import { getUserHasAccess } from "../../../utils/authorities";
import { useSelector } from "react-redux";
export const CustomWrapperCard = ({
  handleAddButtonCard,
  handleDelete,
  handleEdit,
  loading,
  title,
  ShowEditorCount,
  disableAddButton,
  data,
  editDeleteBtn = true,
  ShowPI,
  ShowProgressBar
}) => {
  const LoginData = useSelector((state) => state.loginData);
  const RoleId = LoginData.role_id;
  const userAuthority = LoginData?.auth || [];
  return (
    <div className="flex flex-col lg:grid grid-cols-12 gap-4 p-3">
      <div className="col-start-1  col-end-5">
        {!loading && <CustomProductDetailCard
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          data={{
            phase_code: data?.phase_code || "",
            title: data?.title ? `${data?.product_details?.name}:  ${data?.title}` : "",
            phase_start_date: data?.phase_start_date || "",
            phase_status_details: data?.phase_status_details?.status || "",
            phase_type_details: data?.phase_type_details?.name || "",
          }}
          editDeleteBtn={editDeleteBtn}
        />}
      </div>
      <div className="col-start-5 col-end-13 ">
        <div className="sm:flex gap-3 justify-end h-100">
          {loading ? (
            <SkeletonLoader
              rowCount={2}
              isTable={false}
              width={"60%"}
              height={145}
            />
          ) : (
            <>
              <CustomProgressCard
                progress={ShowProgressBar ?? true}
                total={data?.subject_details?.subjectCount || 0}
                title={"Total Enrolled Patients"}
                percentage={data?.subject_details?.percentageComplete || 0}
                minTotal={`Min no of Patients:  ${data?.minimum_subjects}`}
                icon={<PersonSvg />}
                RoleId={RoleId}
              />
              <hr className="ml-[0.5rem] mr-[0.5rem] sm:hidden" />
              <CustomProgressCard
                progress={false}
                total={
                  (!ShowPI && !ShowEditorCount && (data?.investigator_details?.investigatorCount || "0")) ||
                  (ShowEditorCount && ShowPI && data?.editorCount) ||
                  (ShowEditorCount && data?.editorCount)
                }
                title={
                  (!ShowPI && (title ?? "Total Principal Investigators")) ||
                  (ShowPI && ShowEditorCount && "Total Editors")
                }
                icon={<InvestigatorIcon />}
                minTotal={
                  ShowPI
                    ? `Medical Expert: ${data?.investigator_details?.coordinating_pi?.name}`
                    : `Medical Expert: ${data?.investigator_details?.coordinating_pi?.name}`
                }
              />
              {(RoleId !='1' && RoleId != '4') && getUserHasAccess(userAuthority, "subject", 16) && (
                <div
                  className={`${disableAddButton ? 'bg-[#F0F1F5] cursor-not-allowed ' : 'bg-[#3571FF] cursor-pointer'} d-flex justify-center align-items-center    w-100  p-2 gap-3 rounded-lg  `}
                  onClick={() => !disableAddButton && handleAddButtonCard()}
                >
                  <div>
                    <div className="d-flex justify-center pb-2">
                      <SubjectButton />
                    </div>
                    <Typography variant="body1" color={"white"}>
                        Add New Patient
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
