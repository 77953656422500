import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { CloseIcon, DownloadButton, PdfIcon } from "../../assets/common-svg/CommonSvg";
import { Formik } from "formik";
import CustomDatePicker from "../custom-date-picker/CustomDatePicker";
import CustomDropdown from "../custom-dropdown/CustomDropdown";
import CustomInputField from "../custom-input/CustomInput";
import { LoadingButton } from "@mui/lab";
import { CustomUpload } from "../custom-upload/CustomUpload";
import { IconButton } from "@mui/material";
import CustomMultiSelectDropdown from "../custom-multiselect-dropdown/MultiSelectDropdown";
import CustomCheckbox from "../custom-checkbox/CustomCheckbox";
import { getUserHasAccess } from "../../utils/authorities";
import { useSelector } from "react-redux";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 300, sm: 400, md: 500, lg: 400 },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    py: "15px",
    maxHeight: {
        xs: "95vh",
        sm: "95vh",
        md: "95vh",
        lg: "90vh",
        xl: "95vh", // Fixed height for xl and above
    },
};

export default function CustomModel({
  open,
  setOpen,
  modelData,
  onChange,
  handleModelValues,
  modalInitialValues,
  initialValuesValidationSchema,
  rowId,
  isLoadingBtn = false,
  buttonRejectAccept,
  setType,
  mediaData,
  resistOverFlow,
  loader = false
}) {
      const LoginData = useSelector((state) => state.loginData);    
    const userAuthority = LoginData?.auth || [];
    const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    const filePath = mediaData?.file_path;
    const fileName = mediaData?.file_name;

    const link = document.createElement("a");
    link.href = filePath;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Slide
            direction="left"
            in={open}
            mountOnEnter
            unmountOnExit
          >
                <div className="relative h-full w-full">
                <Box sx={style} className="relative">
                  <div className="pb-[10px] border-b border-[#DDDDDD]">
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      textAlign={"center"}
                      component="h6"
                    >
                      {rowId
                        ? modelData?.editTitle 
                        : modelData?.title }
                    </Typography>
                  </div>                   
                                <div onClick={handleClose} className=" absolute right-0 1sm:right-[-30px] top-[-30px] cursor-pointer">
                                    <Tooltip title="close"><div><CloseIcon /></div></Tooltip>
                                </div>
                                <Formik
                                    enableReinitialize
                                    initialValues={modalInitialValues}
                                    validationSchema={initialValuesValidationSchema}
                                    onSubmit={(values) => {
                                        handleModelValues(values);
                                    }}>
                                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (

                                        <form onSubmit={handleSubmit} className='flex flex-col gap-7'>
                                            <div className={`p-3 modalInner ${resistOverFlow ? '!overflow-visible':'!overflow-auto'} flex flex-col gap-6`}>
                                                {modelData?.children?.map((child, index) => {
                                                    if (child?.type == "input") {
                                                        return <CustomInputField
                                                            key={index}
                                                            htmlFor={child?.name}
                                                            label={child?.label}
                                                            IsRequired={child?.IsRequired}
                                                            fullWidthBoolean={true}
                                                            InputId={child?.name}
                                                            InputType="text"
                                                            spacing={1}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            InputName={child?.name}
                                                            CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                                                            placeholder={child?.placeholder}
                                                            LabelClasses={"text-[16px] text-[#000000]"}
                                                            error={Boolean(touched[child?.name] && errors[child?.name])}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                            value={values[child?.name]}
                                                            disabled={child?.disabled}
                                                        />
                                                    }
                                                    if (child?.type == "number") {
                                                        return <CustomInputField
                                                            key={index}
                                                            htmlFor={child?.name}
                                                            label={child?.label}
                                                            IsRequired={child?.IsRequired}
                                                            fullWidthBoolean={true}
                                                            InputId={child?.name}
                                                            InputType="number"
                                                            minValueNumber={child?.minValueNumber || 0}
                                                            spacing={1}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            InputName={child?.name}
                                                            CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                                                            placeholder={child?.placeholder}
                                                            LabelClasses={"text-[16px] text-[#000000]"}
                                                            error={Boolean(touched[child?.name] && errors[child?.name])}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                            value={values[child.name]}
                                                            disabled={child?.disabled}

                                                        />
                                                    }
                                                    if (child?.type == "upload") {
                                                        return <CustomUpload
                                                            key={index}
                                                            label={child?.label}
                                                            InputId={child?.name}
                                                            name={child?.name}
                                                            placeholder="Upload a file"
                                                            value={values[child?.name]?.name}
                                                            handleChange={(event) => {
                                                                setFieldValue(child?.name, event.currentTarget.files[0]);
                                                            }}
                                                            error={Boolean(touched[child?.name] && errors[child?.name])}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                        />
                                                    }
                                                    if (child?.type == "date") {
                                                        return <CustomDatePicker
                                                            key={index}
                                                            LabelText={child?.label}
                                                            BgColor={"#EDEDED"}
                                                            onBlur={handleBlur}
                                                            handleChange={(date) => {
                                                                setFieldValue(child?.name, date);
                                                            }}
                                                            minDate={child?.minDate}
                                                            htmlFor={child?.name}
                                                            FontSize={"15px"}
                                                            IsRequired={child?.IsRequired}
                                                            PaddingY={"2px"}
                                                            InputId={child?.name}
                                                            InputName={child?.name}
                                                            placeholder={child?.placeholder}
                                                            error={Boolean(
                                                                touched[child?.name] && errors[child?.name]
                                                            )}
                                                            maxDate={child?.maxDate}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                            value={values[child?.name]}
                                                            disabled={child?.disabled}

                                                        />
                                                    }
                                                    if (child?.type == "dropdown") {
                                                        return <CustomDropdown
                                                            key={index}
                                                            IsRequired={child?.IsRequired}
                                                            DropDownlabel={child?.label}
                                                            fullWidthBoolean={true}
                                                            ShowLargeDropdown={true}
                                                            PlaceholderText={child?.placeholder}
                                                            disabled={child?.disabled}
                                                            onBlur={handleBlur}
                                                            handleChange={(e) => {
                                                                if (child?.name == 'state') {
                                                                    onChange(e.target.value, values)
                                                                } else if (child?.name == 'principalInvestigator') {
                                                                    onChange(e.target.value, values, child?.name)
                                                                    setFieldValue('trial', '')
                                                                }
                                                                 else if (child?.name == 'principal_investigator') {
                                                                    onChange(e.target.value)
                                                                    setFieldValue('editor_id', '')                                                                    
                                                                }
                                                                else if (child?.name == 'investigator_id') {
                                                                    onChange(e.target.value)
                                                                    setFieldValue('editor_id', '')
                                                                } else if (child?.name == 'phase') {
                                                                    onChange(e.target.value, child?.name)
                                                                    setFieldValue('template', '')
                                                                    setFieldValue('formField', '')
                                                                } else if (child?.name == 'template') {
                                                                    onChange(e.target.value, child?.name)
                                                                    setFieldValue('formField', '')
                                                                } else if (child?.name == 'userType') {
                                                                    onChange(e.target.value, child?.name)
                                                                } else if (child?.id == 'selectFormDp') {
                                                                    onChange(e.target.value, child?.name)
                                                                    if(!e.target.value){
                                                                        setFieldValue('questionId', [])
                                                                    }                          
                                                                }
                                                                handleChange({
                                                                    target: {
                                                                        name: child?.name,
                                                                        value: e.target.value,
                                                                        type: "select"
                                                                    }
                                                                })
                                                            }}
                                                            error={Boolean(touched[child?.name] && errors[child?.name])}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                            MenuList={child.data}
                                                            value={values[child.name]}
                                                        />
                                                    }
                                                    if (child?.type == "description") {
                                                        return <CustomInputField
                                                            key={index}
                                                            htmlFor="edit-product"
                                                            label={child?.label}
                                                            customDivClass="!mb-8"
                                                            fullWidthBoolean={true}
                                                            IsRequired={child?.IsRequired}
                                                            InputId={child?.name}
                                                            InputName={child?.name}
                                                            InputType="text"
                                                            value={values[child?.name]}
                                                            spacing={1}
                                                            onChange={handleChange}
                                                            error={Boolean(touched[child?.name] && errors[child?.name])}
                                                            MultiLine={true}
                                                            NoOfRows={3}
                                                            onBlur={handleBlur}
                                                            CustomClassName="bg-[#F0F1F5] text-[black] font-normal text-[18px]"
                                                            placeholder={child?.placeholder}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                            LabelClasses={"text-[16px] text-[#000000]"}
                                                            disabled={child?.disabled}

                                                        />
                                                    }
                                                    if(child?.type == 'checkbox'){
                                                        return <CustomCheckbox key={index}           
                                                            IsRequired={child?.IsRequired}
                                                            InputId={child?.name}
                                                            InputName={child?.name}
                                                            InputType="text"
                                                            value={values[child?.name]}
                                                            spacing={1}
                                                            onChange={(e) => {
                                                                setFieldValue("terms", e.target.checked)
                                                            }}
                                                            error={Boolean(touched[child?.name] && errors[child?.name])}
                                                            MultiLine={true}
                                                            NoOfRows={3}
                                                            onBlur={handleBlur}
                                                            placeholder={child?.placeholder}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                            disabled={child?.disabled}                      
                                                            />
                                                    }
                                                    if (child?.type == 'multidropdown') {
                                                        return <CustomMultiSelectDropdown
                                                            key={index}
                                                            IsRequired={child?.IsRequired}
                                                            DropDownlabel={child?.label}
                                                            fullWidthBoolean={true}
                                                            ShowLargeDropdown={true}
                                                            PlaceholderText={child?.placeholder}
                                                            disabled={child?.disabled}
                                                            onBlur={handleBlur}
                                                            handleChange={(selectedValues) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: child?.name,
                                                                        value: selectedValues,
                                                                        type: "select"
                                                                    }
                                                                });
                                                                if(child?.name === "formId"){
                                                                    setFieldValue('questionId', [])
                                                                }
                                                                onChange(selectedValues, child?.name)
                                                            }}
                                                            error={Boolean(touched[child?.name] && errors[child?.name])}
                                                            touched={touched[child?.name]}
                                                            helperText={errors[child?.name]}
                                                            MenuList={child.data}
                                                            value={Array.isArray(values[child.name]) ? values[child.name] : []} // Ensure value is array
                                                            multiple={true} // Enable multi-select
                                                            showSelectAll={child?.showSelectAll}
                                                            grouping = {child?.name === "questionId"}
                                                        />



                                                    }
                                                })}
                                                <div>
                                                    {buttonRejectAccept && (
                                                        <>
                                                            <div className="border-2 border-dashed border-gray-300 px-4 py-1 rounded-md flex items-center justify-between">
                                                                <label htmlFor="file-input" className="flex gap-2 !m-0 items-center">
                                                                    <PdfIcon />
                                                                    <span className="text-sm leading-normal">{mediaData?.file_name}</span>
                                                                    <input className="hidden" id="file-input" />
                                                                </label>
                                                                <IconButton onClick={handleDownload}>
                                                                    <DownloadButton width={20} height={45} />
                                                                </IconButton>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                
                                                    {buttonRejectAccept === 'pending' ? (
                                                    <div className="flex gap-4 items-center">
                                                        <LoadingButton
                                                        variant="outlined"
                                                        fullWidth
                                                        type="submit"
                                                        size="large"
                                                        onClick={() => {
                                                            setType('Reject');
                                                            handleSubmit();
                                                        }}
                                                        disabled={isLoadingBtn}
                                                        >
                                                        <span>Reject</span>
                                                        </LoadingButton>
                                                        <LoadingButton
                                                        variant="contained"
                                                        fullWidth
                                                        type="submit"
                                                        size="large"
                                                        onClick={() => {
                                                            setType('Accept');
                                                            handleSubmit();
                                                        }}
                                                        disabled={isLoadingBtn}
                                                        >
                                                        <span>Accept</span>
                                                        </LoadingButton>
                                                    </div>
                                                    ) : buttonRejectAccept === 'rejected' ? null : (
                                                    rowId && modelData?.editTitle==='Edit Patient' ? (
                                                        getUserHasAccess(userAuthority, 'subject_pending', 161) && (
                                                        <LoadingButton
                                                            variant="contained"
                                                            fullWidth
                                                            type="submit"
                                                            size="large"
                                                            onClick={handleSubmit}
                                                            disabled={isLoadingBtn}
                                                            className="min-h-[44px]"
                                                        >
                                                            {loader ? (
                                                            <div
                                                                style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                                }}
                                                            >
                                                                <div className="circular-loader"></div>
                                                            </div>
                                                            ) : (
                                                            <span>{rowId ? "Update" : "Submit"}</span>
                                                            )}
                                                        </LoadingButton>
                                                        )
                                                    ) : (
                                                        <LoadingButton
                                                        variant="contained"
                                                        fullWidth
                                                        type="submit"
                                                        size="large"
                                                        onClick={handleSubmit}
                                                        disabled={isLoadingBtn}
                                                        className="min-h-[44px]"
                                                        >
                                                        {loader ? (
                                                            <div
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                            }}
                                                            >
                                                            <div className="circular-loader"></div>
                                                            </div>
                                                        ) : (
                                                            <span>{rowId ? "Update" : "Submit"}</span>
                                                        )}
                                                        </LoadingButton>
                                                    )
                                                    )}
                                            </div>                    
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </div>
                </Slide>

                </Fade>
            </Modal>
        </div>
    );
}

CustomModel.proptypes = {
  children: PropTypes.node,
  editHospitalBoolean: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  ModalTitle: PropTypes.string,
  SetValue: PropTypes.func,
  resistOverFlow: PropTypes.bool
};
