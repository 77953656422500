/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import Loadable from "../../components/Loadable";
import {
  DocumentIcon,
  InvestigatorIcon,
  PersonSvg,
} from "../../assets/common-svg/CommonSvg";
import { CustomChipCard } from "../../components/custom-product-cards/custom-chip-card/CustomChipCard";
import { CustomWrapperCard } from "../../components/custom-product-cards/custom-wrapper-card/CustomWrapperCard";
import CustomDetailComponent from "../../components/doctor-detail-component/DocotorDetail";
import TrialInfoComponent from "../../components/trial-info-component/TrialInfoComponent";
import CustomTable from "../../components/custom-table/CustomTable";
import { useLocation, useNavigate, useParams } from "react-router";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { Typography, useTheme } from "@mui/material";
const TaskManagementHeader = Loadable(
  React.lazy(() =>
    import("../../components/task-management-header/TaskManagementHeader")
  )
);
import SkeletonLoader from "../../utilites/SkeltonLoader";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import {
  AddPhase,
  EditSubjectPhase,
  ReAssignPiEditor,
  VisitFrequency,
} from "../../utils/formik/initialValues";
import {
  ReAssignValidationSchema,
  addFormExportSchema,
  addPhaseValidationSchema,
  editSubjectPhaseValidationSchema,
  validateEditSubjectId,
  visitFrequencyValidationSchema,
} from "../../utils/formik/validationSchema";
import CustomModel from "../../components/custom-model/CustomModel";
import { productDetailsData } from "../../utils/tableData";
import api from "../../services/request";
import SkeltonLoaderHeader from "../../utilites/SkeltonLoaderHeader";
import NoDataCard from "../../components/no-data-card.js";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormTemplateDropList,
  setIAQA,
  setPICoordinator,
  setPhaseType,
  setTrialManager,
} from "../../store/reducers/sub-admin-dropdown.js";
import SubjectApi from "../../services/subjectService.js";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar.js";
import { setSnackbar } from "../../store/reducers/snackBar.js";
import { setPhaseDetail } from "../../store/reducers/phase-reducer.js";
import moment from "moment";
import PhaseApi from "../../services/phase.js";
import SubAdminApi from "../../services/subAdminService.js";
import {
  AddNewSubjectPhase,
  exportFormCsv,
  editSubjectId,
} from "../../utils/formik/initialValues";
import { addNewSubjectPhaseValidationSchema } from "../../utils/formik/validationSchema";
import DiseaseManagementApi from "../../services/diseaseService";
import { GenderList } from "../../utils/common";
import ProductApi from "../../services/productService.js";
import AddPiAndEditorForAllModalContent from "../../components/modal-contents/AddPiAndEditorForAllModalContent .js";
import { useSearchParams } from "react-router-dom";
import { downloadCsv, jsonToCsv } from "../../utils/utils.js";
import { isEmpty } from "lodash";
import ConfirmationModal from "../../components/modal-contents/ConfirmationModal.js";
import { formTemplateDropdownList } from "../../utils/common";
import * as Yup from "yup";
import CustomPhaseModel from "../../components/custom-modal/CustomPhaseModal.js";
import { getUserHasAccess } from "../../utils/authorities.js";

export const convertSubjectData = (data, curr_page, page_details, order) => {
  return data?.map((sub, index) => {
    let SerialNo =
      order == "desc"
        ? page_details?.no_of_records - (curr_page - 1) * 10 - index
        : index + 1 + (curr_page - 1) * 10;
    let transformedPatientId = sub?.patient_id || "--";
    let transformId = sub?.id;
    let transformedSubjectInitial =
      sub?.first_name + " " + sub?.last_name || "";
    let transformedPatientStatus =
      sub?.life_status == "lost" ? "Lost To Follow Up" : sub?.life_status;
    let transformedLastVisitType = sub?.last_visit_type || "--";
    let transformeInvestigator =
      sub?.investigatorDetails?.name || "Not Assigned";
    let transformedEditor = sub?.editorDetails?.name || "Not Assigned";
    let cycleCount = sub?.cycle;
    let gender = sub?.gender;
    let lastAppointmentDate = sub?.last_appointment_date
      ? moment(sub?.last_appointment_date).format("DD/MM/YYYY")
      : "--";
    let hospitalName = sub?.hospitalDetails?.name;
    return [
      SerialNo,
      transformedPatientId,
      transformedSubjectInitial,
      transformedPatientStatus,
      transformedLastVisitType,
      transformeInvestigator,
      transformedEditor,
      cycleCount,
      gender,
      hospitalName,
      lastAppointmentDate,
      transformId,
    ];
  });
};




export const ProductDetails = () => {
  const snackbar = useSelector((state) => state.snackBar);
  const { role_id, auth } = useSelector((state) => state.loginData);
  const userAuthority = auth || [];
  const [activeTab, setActiveTab] = useState(null);
  const { pICoordinator, trialManager, iAQA, phaseType, formTemplateDropList } =
    useSelector((state) => state?.subAdminDropdown);
  const [phaseDetailLoading, setPhaseDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddTrailPhaseModal, setOpenAddTrailPhaseModal] = useState(false);
  const [phaseListData, setPhaseListData] = useState(null);
  const [phaseListDetails, setPhaseListDetails] = useState({});
  const [phaseListLoading, setPhaseListLoading] = useState(false);
  const [assigningPiLoader, setAssignPiLoader] = useState(false);
  const [openAssignForAllModal, setOpenAssignForAllModal] = useState(false);
  const [sumbitPiEditorValues, setSubmitPiEditorValues] = useState({});
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isAddPrLoading, setIsAddPrLoading] = useState(false);
  const [isAddSubjectLoading, SetIsAddSubjectLoading] = useState(false);
  const [editorList, setEditorList] = useState([]);
  const [subjectData, setSubjectData] = useState(productDetailsData);
  const [tableFilters, setTablesFilters] = useState({ searchData: "" });
  const [totalPage, setTotalPage] = useState(1);
  const [subjectListData, setSubjectListData] = useState([]);
  const [initialValues, setInitialValues] = useState(ReAssignPiEditor);
  const [currentPage, setCurrentpage] = useState(1);
  const [pIList, setPIList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [piId, setPiID] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [editorId, setEditorId] = useState(null);
  const [openAddSubjectModal, setOpenAddSubjectModal] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openEditSubjectIdModal, setOpenEditSubjectIdModal] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [searchParams] = useSearchParams();
  const [exportLoading, setExportLoading] = useState(false);
  const [editIdLoading, setEditIdLoading] = useState(false);
  const [patientCycleConfirm, setPatientCycleConfirm] = useState(false);
  const [patientCycleErrMess, setPatientCycleErrMess] = useState("");
  const [patientFormValues, setPatientFormValues] = useState({});
  const [patientCycleLoading, setPatientCycleLoading] = useState(false);
  const [selectedTemplateList, setSelectedTemplateList] = useState([]);
  const [addPhaseValues, setAddPhaseValues] = useState(null);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [patientEditId, setPatientEditId] = useState(editSubjectId);
  const [patientEdit, setPatientEdit] = useState(EditSubjectPhase);
  const [editSubject, setEditSubject] = useState("");
  const [openAddPhaseModal, setOpenAddPhaseModal] = useState(false);
  const [visitFrequencies, setVisitFrequencies] = useState({});
  const [templateList, setTemplateList] = useState([]);
  const [initialPhaseModal, setInitialPhaseModal] = useState(false);
  const [getTemplateTypeIds, setTemplateTypeIds] = useState(null);
  const [selectFormType, setSelectFormType] = useState([]);
  const [formTypeListLoading, setformTypeListLoading] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(null);
  const [isAddPhaseLoading, setIsAddPhaseLoading] = useState(false);
  const [isTrialInfoLoading, setIsTrialInfoLoading] = useState(false);
  const [isSwap,setIsSwap]=useState(false)
  const [gender,setGender]=useState('')
    const [count,setCount]=useState(1);
  // Get the value of a specific query parameter
  const activeTrialId = searchParams.get("phase_id");
  const [subjectModelData, setSubjectModelData] = useState({
    title: "Add New Patient",
    editTitle: "Re-Assign PI/Editor",
    children: [
      {
        type: "input",
        name: "first_name",
        label: "First Name",
        placeholder: "Enter First Name",
        IsRequired: true,
      },
      {
        type: "input",
        name: "last_name",
        label: "Last Name",
        placeholder: "Enter Last Name",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "gender",
        label: "Gender",
        placeholder: "Select gender",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "investigator_id",
        label: "Principal Investigator",
        placeholder: "Select principal investigator",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "editor_id",
        label: "Editor",
        placeholder: "Select Editor",
        data: [],
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "disease_id",
        label: "Disease",
        placeholder: "Select disease",
        data: [],
        IsRequired: true,
      },
      {
        type: "date",
        name: "dob",
        label: "Date of Birth",
        placeholder: "Select date",
        minDate: false,
        maxDate: new Date(),
        IsRequired: true,
      },
    ],
  });

   const [modelData, setModelData] = useState({
    title: "Add Phase",
    children: [
      {
        type: "input",
        name: "title",
        label: "Title",
        placeholder: "Enter Title",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "phase_type",
        label: "Phase Type",
        placeholder: "Select Phase",
        IsRequired: true,
      },
      {
        type: "date",
        name: "phase_start_date",
        label: "Phase Start Date",
        placeholder: "Select date",
        IsRequired: true,
      },
      {
        type: "input",
        name: "minimum_subjects",
        label: "Minimum no of Patients",
        placeholder: "Enter Number",
        IsRequired: true,
      },
      {
        type: "dropdown",
        name: "coordinating_PI_id",
        label: "Medical Expert ",
        placeholder: "Select Medical Expert",
        IsRequired: true,
      },
      // {
      //   type: "multidropdown",
      //   name: "template_type_ids",
      //   id: "template_type_ids",
      //   label: "Required Visit Types",
      //   data: formTemplateDropdownList,
      //   showSelectAll: true,
      //   placeholder: "Select Visit Types",
      //   IsRequired: true,
      // },
      {
        type: "description",
        name: "description",
        label: "Description",
        placeholder: "Description",
        IsRequired: true,
      },
    ],
  });
  const [openVisitNumberModel, setOpenVisitNumberModel] = useState(false);
  const [visitNumberModelData, setVisitNumberModelData] = useState({
    title: "Visit Frequency",
    children: [],
  });
  const [visitNumberIntialValue, setVisitNumberIntialValue] = useState({});
  const [exportModelData, setExportModelData] = useState({
    title: "Export Patients Data",
    children: [
      {
        type: "multidropdown",
        name: "formId",
        id: "selectFormDp",
        label: "Select Form",
        placeholder: "Select Form",
        data: [],
        IsRequired: true,
        showSelectAll: true,
      },
    ],
  });

  const [visitFrequencyModelData, setVisitFrequencyModelData] = useState({
    title: "Add visit frequency",
    children: [
      {
        type: "number",
        name: "template_type_ids",
        id: "template_type_ids",
        label: "",
        placeholder: "Enter visit frequency",
        data: [],
        IsRequired: true,
      },
    ],
  });

  const [defaultEditSubjectId, setDefaultEditSubjectId] = useState({
    title: "Edit Patient Id",
    editTitle: "Edit Patient Id",
    children: [
      {
        type: "input",
        name: "patientId",
        id: "patient_id",
        label:
          "Ensure that the entered Patient ID is same as received from ImmunoACT",
        placeholder: "Enter patient id",
        value: "",
        IsRequired: true,
      },
    ],
  });

  const [formQsList, setFormQsList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    let newChildren = [];
    if (!rowId || !isSwap || subjectModelData?.editTitle == "Edit Patient" ) {
      newChildren = [
        {
          type: "input",
          name: "first_name",
          label: "First Name",
          placeholder: "Enter First Name",
          IsRequired: true,
        },
        {
          type: "input",
          name: "last_name",
          label: "Last Name",
          placeholder: "Enter Last Name",
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "gender",
          label: "Gender",
          placeholder: "Select gender",
          data: GenderList,
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "investigator_id",
          label: "Principal Investigator",
          placeholder: "Select principal investigator",
          data: pIList,
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "editor_id",
          label: "Editor",
          placeholder: "Select Editor",
          data: [],
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "disease_id",
          label: "Disease",
          placeholder: "Select disease",
          data: diseaseList,
          IsRequired: true,
          disabled: rowId && subjectModelData?.editTitle === "Edit Patient",  //@Use:: To disbale disease on edit patient
        },
        {
          type: "date",
          name: "dob",
          label: "Date of Birth",
          placeholder: "Select date",
          minDate: false,
          maxDate: new Date(),
          IsRequired: true,
        },
      ];
    } else {
      newChildren = [
        {
          type: "dropdown",
          name: "investigator_id",
          label: "Principal Investigator",
          placeholder: "Select principal investigator",
          data: pIList,
          IsRequired: true,
        },
        {
          type: "dropdown",
          name: "editor_id",
          label: "Editor",
          placeholder: "Select Editor",
          data: [],
          IsRequired: true,
        },
      ];
    }

    setSubjectModelData((prevState) => ({
      ...prevState,
      children: newChildren,
    }));
  }, [editorList, rowId, isSwap]);

  const SumbitAssignAllPiAndEditor = (action) => {
    setOpenAssignForAllModal(false);
    if (action === "YES") {
      handleUpdatePiEditor({
        investigator_id: sumbitPiEditorValues?.investigator_id,
        editor_id: sumbitPiEditorValues?.editor_id,
        phase_id: phaseListDetails?.id,
      });
    } else if (action === "NO") {
      handleUpdatePiEditor({
        investigator_id: sumbitPiEditorValues?.investigator_id,
        editor_id: sumbitPiEditorValues?.editor_id,
        subject_id: rowId,
        phase_id: phaseListDetails?.id,
      });
    }
  };

  const handleTemplateList = (data) => {
    setSelectedTemplateList(data);
  };

  React.useEffect(() => {
    if (Object.keys(sumbitPiEditorValues).length > 0) {
      setOpenAddSubjectModal(false);
      setTimeout(() => {
        setOpenAssignForAllModal(true);
      }, 200);
    }
  }, [sumbitPiEditorValues]);

  const handleOpenAssignPiAndEditorModal = (values) => {
    const subData = subjectListData.find((sub) => sub.id === rowId);
    if (
      Object.keys(subData?.editorDetails).length > 0 &&
      Object.keys(subData?.investigatorDetails).length > 0
    ) {
      handleUpdatePiEditor({
        investigator_id: values?.investigator_id,
        editor_id: values?.editor_id,
        subject_id: rowId,
        phase_id: phaseListDetails?.id,
      });
    } else {
      setSubmitPiEditorValues(values);
    }
  };

  const handleNavigateToDetails = useCallback((row) => {
    const UpdatedNavigationArray = location.state;
    UpdatedNavigationArray?.push({
      name: "Patient Details",
      path: `${location.pathname}/patient-details/${row[row.length - 1]}`,
    });
    navigate(`patient-details/${row[row.length - 1]}`, {
      state: UpdatedNavigationArray,
    });
  }, []);
  const handleEdit = useCallback(() => {
    const UpdatedNavigationArray = location.state;
    UpdatedNavigationArray?.push({
      name: "Edit Details",
      path: `${location.pathname}/edit-trial-details/${phaseListDetails?.id}`,
    });
    navigate(`edit-trial-details/${phaseListDetails?.id}`, {
      state: UpdatedNavigationArray,
    });
  }, [phaseListDetails?.id]);
  const handleDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleOpenTrailPhaseModal = () => {
    setOpenAddTrailPhaseModal(true);
  };

  const handleAddPhaseModal = () => {
    if (phaseListData?.length > 0) {
      setIsAddPhaseLoading(false);
      setIsTrialInfoLoading(false);
    } else {
      setIsAddPhaseLoading(true);
      setIsTrialInfoLoading(true);
    }
    setOpenAddPhaseModal(true);
    fetchData();
  };

  const handleOpenAddSubjectModal = () => {
    if (
      phaseListDetails?.investigator_details?.investigator_id?.length == 0
    ) {
      dispatch(
        setSnackbar({
          value: true,
          message: "You can't add patient until PI is not added for phase !",
          color: "error",
        })
      );
    } else {
      setInitialValues({
        investigator_id: "",
        editor_id: "",
      });
      setRowId(null);
      setOpenAddSubjectModal(true);
    }
  };
  const handleVisitFrequency = (values) => {
    setVisitFrequencies(values);
  };

  const handleAddVisitNumber = async (values) => {
    resetSortFilter();

    // const result = formTemplateDropdownList?.filter((item) => selectedTemplateList.includes(item.id.toString())) // Compare id with selectedTemplateList
    const result = selectFormType.map((item) => ({
      type: "number",
      name: `${item.id}`, // You can dynamically assign a unique name
      label: `${item.name}`, // Label with type name
      placeholder: "Enter Number",
      IsRequired: true,
      minValueNumber: 1,
    }));
    const visitIntialVal = {};

    result?.forEach((item) => {
      visitIntialVal[item.name.toString()] = 1;
    });
    setVisitNumberIntialValue(visitIntialVal);

    // Set the formatted result into visitNumberModelData's children
    setVisitNumberModelData((prevData) => ({
      ...prevData,
      children: result,
      // Update children with the result in the specified format
    }));

    // setOpenVisitNumberModel(true);
    // Normalize the array fields
    const normalizeArrayField = (field) => (field ? [field] : []);
    // const filteredTemplateTypes= delete visitFrequencies.template_type_ids;
    // Create updated values

    const updatedValues = {
      ...values,
      qa_ids: normalizeArrayField(values.qa_ids),
      template_type_ids: visitFrequencies.template_type_array,
      manager_ids: normalizeArrayField(values.manager_ids),
      form_template_ids: normalizeArrayField(values.form_template_ids),
      phase_start_date: moment(values.phase_start_date).format("MM/DD/YYYY"),
      product_id: id,
      status: 1,
    };

    try {
      setIsAddPrLoading(true);

      // API call to add phase
      const response = await PhaseApi.addPhase(updatedValues);

      await getPhaseList();
      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Phase added successfully",
          color: "success",
        })
      );

      // Close the modal
      // setOpenVisitNumberModel(false);
      setInitialPhaseModal(false);
      setOpenAddTrailPhaseModal(false);
      setIsAddPhaseLoading(false);
      setIsTrialInfoLoading(false);
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error.message || error || "Failed to add phase",
          color: "error",
        })
      );
    } finally {
      setIsAddPrLoading(false); // Reset loading state
    }
  };

  const addVisitTypeNumValidationSchema = (dynamicFields) => {
    const schema = {};
    // Check if children exist
    if (dynamicFields?.children?.length) {
      dynamicFields.children.forEach((field) => {
        if (field.type === "number") {
          schema[field.name] = Yup.number()
            .required(`${field.label} is required`)
            .min(1, `${field.label} must be greater than 0`)
            .integer(`${field.label} must be an integer`);
        }
      });
    }
    return Yup.object().shape(schema); // Return the generated validation schema
  };

  const handleAddPhase = async (values) => {
    const result = formTemplateDropdownList
      ?.filter((item) => selectedTemplateList.includes(item.id.toString())) // Compare id with selectedTemplateList
      .map((item) => ({
        type: "number",
        name: `${item.id}`, // You can dynamically assign a unique name
        label: `${item.name}`, // Label with type name
        placeholder: "Enter Number",
        IsRequired: true,
        minValueNumber: 1,
      }));

    const visitIntialVal = {};

    result?.forEach((item) => {
      visitIntialVal[item.name.toString()] = 1;
    });
    setVisitNumberIntialValue(visitIntialVal);

    // Set the formatted result into visitNumberModelData's children
    setVisitNumberModelData((prevData) => ({
      ...prevData,
      children: result,
      // Update children with the result in the specified format
    }));

    // setOpenVisitNumberModel(true);

    // Normalize the array fields
    const normalizeArrayField = (field) => (field ? [field] : []);
    // const filteredTemplateTypes= delete visitFrequencies.template_type_ids;
    // Create updated values
    const updatedValues = {
      ...values,
      qa_ids: normalizeArrayField(values.qa_ids),
      template_type_ids: visitFrequencies,
      manager_ids: normalizeArrayField(values.manager_ids),
      form_template_ids: normalizeArrayField(values.form_template_ids),
      phase_start_date: moment(values.phase_start_date).format("MM/DD/YYYY"),
      product_id: id,
      status: 1,
    };
    setAddPhaseValues(updatedValues);
  };

  const handleAddSubject = async (values, confirmation) => {
    SetIsAddSubjectLoading(true);
    setPatientFormValues(values);
    const formData = new FormData();
    formData.append("first_name", values.first_name?.trim());
    formData.append("last_name", values.last_name?.trim());
    if (values.upload_file) {
      if (values.upload_file instanceof FileList) {
        for (let i = 0; i < values.upload_file.length; i++) {
          formData.append("files", values.upload_file[i]);
        }
      } else {
        formData.append("files", values.upload_file);
      }
    }
    formData.append(
      "gender",
      GenderList?.find((op) => op?.id == values.gender)?.name
    );
    formData.append("dob", moment(values.dob).format("MM/DD/YYYY"));
    formData.append("disease_id", values.disease_id);
    formData.append("phase_id", phaseListDetails?.id);
    formData.append("phase_code", phaseListDetails?.phase_code);
    formData.append("phase_title", phaseListDetails?.title);
    formData.append("investigator_id", values.investigator_id);
    formData.append("editor_id", values.editor_id);
    formData.append("product_id", id);
    formData.append("addCycle", confirmation || false);
    try {
      // API call to add phase
      const response = await SubjectApi.addSubject(formData);

      await getSubjectList();
      getPhaseDetails(activeTab);
      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Patient Added Successfully",
          color: "success",
        })
      );

      // Close the modal
      setOpenAddSubjectModal(false);
    } catch (error) {
      // Safely access the 'addCycle' key if it exists in the 'errors' array
      const addCycleError = error?.errors?.find((obj) =>
        Object.prototype.hasOwnProperty.call(obj, "addCycle")
      );
      // Log whether 'addCycle' exists in the error and the corresponding value if found
      if (addCycleError) {
        setPatientCycleErrMess(addCycleError?.addCycle);
        setPatientCycleConfirm(true); // Set patient cycle confirmation only if addCycle is found
      } else {
        dispatch(
          setSnackbar({
            value: true,
            message: error.message || error || "Failed to Add Patient",
            color: "error",
          })
        );
      }
    } finally {
      SetIsAddSubjectLoading(false); // Reset loading state
      if (confirmation) {
        setPatientCycleConfirm(false);
      }
    }
  };

  const handlePhaseSearch = (value) => {
    resetSortFilter();
    if (value !== " ") {
      getPhaseList(value);
    }
  };

  async function getPhaseList(search = "", limit = 100, page = 1) {
    setPhaseListLoading(true);
    setLoading(true);
    try {
      const res = await PhaseApi.getPhaseList(id, search, limit, page);
      setPhaseListData(res.data || []);
      if (activeTrialId) {
        setActiveTab(activeTrialId);
      } else {
        setActiveTab(res?.data && res?.data[0].id);
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setPhaseListLoading(false);
      setLoading(false);
    }
  }

  const getProductDetails = async () => {
    const res = await ProductApi.getProductDetails(id);
    setProductDetails(res?.data);
  };

  useEffect(() => {
    getPhaseList();
    getProductDetails();
  }, []);

  function isArrayInArray(data, ndata) {
    return data.every((element) => ndata.includes(+element));
  }

  const handleShowAddSubjectButton = (data) => {
    if (data?.form_details) {
      // Check if all forms are approved
      //  const showButton = data?.form_details.every((value) => value?.is_approved !== false);
      //  setDisableButton(!showButton)

      // }

      let formTempIds = data?.template_type_ids.map((v) => +v);

      const showButton = data?.form_details.map((value) => {
        if (value?.is_approved !== false) {
          return value.type.id;
        }
      });
      const IsEqual = isArrayInArray(formTempIds, showButton);
      setDisableButton(!IsEqual);
    }
  };

  async function getPhaseDetails(activeTab) {
    setPhaseDetailLoading(true);
    try {
      const res = await PhaseApi.getPhaseListDetails(activeTab);
      handleShowAddSubjectButton(res?.data);
      setPhaseListDetails(res?.data || []);
      getSubjectModelData(res?.data);
      dispatch(setPhaseDetail(res?.data));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    } finally {
      setPhaseDetailLoading(false);
    }
  }

  const fetchData = async () => {
    if (loading) return;

    setLoading(true);
    setformTypeListLoading(true);

    try {
      const response = await PhaseApi.getTemplateTypeList(
        page,
        10,
        searchFilter
      );

      if (response.data) {
        setTemplateList((prev) => {
          if (page > 1) {
            return [...prev, ...response.data];
          } else {
            return response.data;
          }
        });

        setMaxPages(
          Math.ceil(
            response.page_details.no_of_records / response.page_details.limit
          )
        );
      } else {
        if (page > 1) {
          setTemplateList((prev) => [...prev]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      dispatch(
        setSnackbar({
          value: true,
          message: error.message || "An error occurred while fetching data.",
          color: "error",
        })
      );
    } finally {
      setLoading(false);
      setformTypeListLoading(false);
    }
  };

  React.useEffect(() => {

    if (page === 1 || page > 1) {
      fetchData();
    }
  }, [page]);

  // Useeffect for call API if 5 types selected 
  React.useEffect(() => {

    if(count === 5) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [count])

  const loadMoreData = () => {
    if (page < maxPages ) {
      setPage((prevPage) => prevPage + 1);
    } else {
      console.error("No more data to load");
    }
  };

  useEffect(() => {
    setEditorId(null);
    setPiID(null);
    if (activeTab) {
      getPhaseDetails(activeTab);
    }
  }, [activeTab]);

  // Function to get Sub Admin Dropdown by ID
  const getSubAdminDropdown = async (id) => {
    try {
      return await SubAdminApi.getSubAdminDropdown(id);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Phase Type Dropdown
  const getPhaseTypeDropDown = async () => {
    try {
      return await PhaseApi.getPhaseTypeDropDown();
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Patient model Data
  const getSubjectModelData = async (phaseRes) => {
    try {
      let desRes = await DiseaseManagementApi.getDiseaseManagementData();
      setDiseaseList(desRes?.data);
      setSubjectModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child) =>
          child.name === "disease_id"
            ? { ...child, data: desRes?.data }
            : child.name == "investigator_id"
            ? {
                ...child,
                data:
                  phaseRes?.investigator_details?.investigator_id ?? [],
              }
            : child.name == "gender"
            ? { ...child, data: GenderList }
            : child
        ),
      }));
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Editor Dropdown
  const getEditorDropdown = async (Pi_id) => {
    try {
      setInitialValues({ investigator_id: Pi_id });
      if (Pi_id) {
        let res = await ProductApi.subadminDropDown(
          5,
          Pi_id,
          phaseListDetails?.id
        );
        setSubjectModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "editor_id" ? { ...child, data: res?.data } : child
          ),
        }));
      } else {
        setSubjectModelData((prevState) => ({
          ...prevState,
          children: prevState.children.map((child) =>
            child.name === "editor_id"
              ? { ...child, placeholder: "Select Editor", data: [] }
              : child
          ),
        }));
      }
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Form Builder Dropdown
  const getFormTemplateDropDown = async () => {
    try {
      return await api.formTemplateDropDown();
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  // Function to get Editor for Patient Sub Admin by Value and Phase List ID
  const getEditorForSubjectSubAdmin = async (value) => {
    try {
      return await SubAdminApi.getEditorForSubjectSubAdmin(
        value,
        phaseListDetails?.id
      );
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  };

  useEffect(() => {
    const fetchSubAdminDropdownData = async () => {
      const results = await Promise.allSettled([
        getSubAdminDropdown(2),
        getSubAdminDropdown(3),
        getSubAdminDropdown(4),
        getPhaseTypeDropDown(),
        getFormTemplateDropDown(),
      ]);

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value?.data || [];
          switch (index) {
            case 0:
              dispatch(setPICoordinator(data));
              break;
            case 1:
              dispatch(setIAQA(data));
              break;
            case 2:
              dispatch(setTrialManager(data));
              break;
            case 3:
              dispatch(setPhaseType(data));
              break;
            case 4:
              dispatch(setFormTemplateDropList(data));
              break;
            default:
              break;
          }
        } else {
          console.error(
            `Error fetching data for index ${index + 2}:`,
            result.reason
          );
        }
      });
    };
    fetchSubAdminDropdownData();
  }, []);

  useEffect(() => {
    if (phaseListDetails?.id) {
      if (
        tableFilters?.searchData[tableFilters?.searchData?.length - 1] != " "
      ) {
        setLoading(true);
        getSubjectList();
      }
    }
  }, [currentPage, tableFilters, phaseListDetails, piId, editorId]);

  useEffect(() => {
    const fetchEdiorData = async () => {
      const results = await Promise.allSettled([
        getEditorForSubjectSubAdmin(2),
        getEditorForSubjectSubAdmin(5),
      ]);

      results?.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value?.data || [];
          switch (index) {
            case 0:
              setPIList(data);
              break;
            case 1:
              setEditorList(data);
              break;
            default:
              break;
          }
        } else {
          console.error(
            `Error fetching data for index ${index + 2}:`,
            result.reason
          );
        }
      });
    };
    if (phaseListDetails?.id) {
      fetchEdiorData();
    }
  }, [phaseListDetails]);

  useEffect(() => {
    if (modelData?.children) {
      const mapedSelectDrop = modelData.children.map((item) => {
        switch (item.name) {
          case "qa_ids":
            return { ...item, data: iAQA };
          case "coordinating_PI_id":
            return { ...item, data: pICoordinator };
          case "manager_ids":
            return { ...item, data: trialManager };
          case "phase_type":
            return { ...item, data: phaseType };
          case "form_template_ids":
            return {
              ...item,
              data: formTemplateDropList?.map((item) => ({
                id: item.id,
                name: item.title,
              })),
            };
          default:
            return item;
        }
      });

      setModelData((prevModelData) => ({
        ...prevModelData,
        children: mapedSelectDrop,
      }));
    }
  }, [iAQA, trialManager, pICoordinator]);

  const GetMatchedKey = (key) => {
    switch (key.trim().toLowerCase()) {
      case "s.no": {
        return "created_at";
      }
      case "patient id": {
        return "patient_id";
      }
      case "patient name": {
        return "name";
      }
      case "patient status": {
        return "life_status";
      }
      case "last recorded visit": {
        return "last_visit_type";
      }
      case "investigator": {
        return "investigator";
      }
      case "phase": {
        return "phase_name";
      }
      case "appointment completed": {
        return "appointmentCount";
      }
      case "editor": {
        return "editor";
      }
      case "cycle no.": {
        return "cycle";
      }
      default:
        return "";
    }
  };

  async function getSubjectList() {
    const sortBy = GetMatchedKey(orderBy);
    try {
      const res = await SubjectApi.getSubjectList(
        phaseListDetails?.id,
        currentPage,
        10,
        tableFilters?.searchData,
        editorId,
        piId,
        "",
        "",
        sortBy,
        order
      );
      let rowData = convertSubjectData(
        res?.data || [],
        currentPage,
        res?.page_details,
        order
      );
      setSubjectData((prev) => ({
        ...prev,
        rows: [...rowData],
      }));
      setSubjectListData(res?.data);
      setTotalPage(
        Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit)
      );
      setCurrentpage(res?.page_details?.page);
      setLoading(false);
    } catch (error) {
      dispatch(setSnackbar({ value: true, message: error, color: "error" }));
    }
  }
  const handleDeletePhase = async () => {
    try {
      setIsDeleteLoading(true);
      // Making API call to delete phase
      const response = await PhaseApi.deletePhase(phaseListDetails?.id);

      // Dispatch success snackbar message
      dispatch(
        setSnackbar({
          value: true,
          message: response.message,
          color: "success",
        })
      );

      // Refresh the phase list
      await getPhaseList();

      // Close the delete modal
      setOpenDeleteModal(false);
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error || error.message || "Failed to delete phase",
          color: "error",
        })
      );
    } finally {
      setIsDeleteLoading(false);
    }
  };
  const handlePagination = async (value) => {
    setCurrentpage(value);
  };

  const handleTableFilters = (e) => {
    setTablesFilters({ ...tableFilters, searchData: e });
    setCurrentpage(1);
  };

  const handleDropDownChange = (e, id) => {
    setCurrentpage(1);
    if (id === "selectPI") {
      setPiID(e.target.value);
    } else if (id === "editorList") {
      setEditorId(e.target.value);
    }
  };

  // @info : Function for change gender value to display into the form 
  const getGender = async (isGenderName) => {
    return new Promise((resolve) => {
      switch (isGenderName) {
        case "Male":
          setGender(1);
          resolve(1);
          break;
        case "Female":
          setGender(2);
          resolve(2);
          break;
        case "Others":
          setGender(3);
          resolve(3);
          break;
        default:
          resolve(null); // Handle unexpected input
          break;
      }
    });
  };
  

  // @info : Function to change gender value to string to send into the payload 
  const changeGenderPayload = (value) => {

    let genderTransform = 'Male';

    switch(value){
      case 1:
        genderTransform = 'Male';
        break;
        case 2:
          genderTransform = 'Female';
          break;
          case 3:
          genderTransform = 'Others';
          break;
      }

      return genderTransform;

  }

  const handleEditSubjectid = (row) => {
    setRowId(row[row.length - 1]);
    setOpenEditSubjectIdModal(true);
    const subData = subjectListData.find(
      (sub) => sub.id === row[row.length - 1]
    );
    setEditSubject(subData?.id);
    setPatientEditId((prevState) => ({
      ...prevState,
      patientId: subData.patient_id,
    }));
  };


const handleEditSubject = async (row) => {
  setOpenAddSubjectModal(true); 
  setIsSwap(false);
  const rowId = row[row.length - 1];
  setRowId(rowId);
  const patientData = subjectListData.find((patient) => patient.id === rowId);

  setSubjectModelData((prev) => ({
    ...prev,
    editTitle: "Edit Patient"
  }));

  const genderValue = await getGender(patientData?.gender); // Wait for gender to be set
  getEditorDropdown(patientData?.investigatorDetails?.id, rowId);

  setPatientEdit((prev) => ({
    ...prev,
    first_name: patientData?.first_name,
    last_name: patientData?.last_name,
    gender: genderValue, // Use the awaited gender value
    dob: patientData?.dob,
    editor_id: patientData?.editorDetails?.id,
    investigator_id: patientData?.investigatorDetails?.id,
    disease_id: patientData?.disease_id,
    phase_id: phaseListDetails?.id,
    phase_code: phaseListDetails?.phase_code,
    phase_title: phaseListDetails?.title,
    addCycle: patientData?.cycle,
  }));
};


  const handleEditSubjectSubmit=async(values)=>{   
    try {

      const payload = {...values};
      payload.gender = changeGenderPayload(values?.gender); // calling function to set gender value 
      const response = await SubjectApi.editPatient(payload,rowId);

      getPhaseDetails(activeTab);
      dispatch(
        setSnackbar({
          value: true,
          message: response.message || "Patient Edited Successfully",
          color: "success",
        })
      );
      await getSubjectList();
      setOpenAddSubjectModal(false)
    } catch (error) {
        dispatch(
          setSnackbar({
            value: true,
            message: error.message || error || "Failed to Edit Patient",
            color: "error",
          })
        );
    } 
  }

  const handleSwapButton = (row) => {
    setIsSwap(true)
    setSubjectModelData((prev) => ({ ...prev, editTitle: "Re-Assign PI/Editor" }));
    setRowId(row[row.length - 1]);
    setOpenAddSubjectModal(true);
    const subData = subjectListData.find(
      (sub) => sub.id === row[row.length - 1]
    );
    // setEditorList([
    //   {
    //     id: subData?.editorDetails?.id,
    //     name: subData?.editorDetails?.name,
    //   },
    // ]);
    getEditorDropdown(subData?.investigatorDetails?.id, row[row.length - 1]);
    setInitialValues({
      investigator_id: subData?.investigatorDetails?.id || "",
      editor_id: subData?.editorDetails?.id || "",
    });
  };

  const handleUpdatePiEditor = async (data) => {
    setAssignPiLoader(true);
    try {
      const response = await ProductApi.updatePiEditor(data);
      setOpenAddSubjectModal(false);
      getSubjectList();
      dispatch(
        setSnackbar({
          value: true,
          message: response.message,
          color: "success",
        })
      );
    } catch (error) {
      dispatch(
        setSnackbar({
          value: true,
          message: error || error.message || "Failed to delete phase",
          color: "error",
        })
      );
    } finally {
      setSubmitPiEditorValues({});
      setAssignPiLoader(false);
    }
  };

  const handleExportButton = () => {
    if (phaseListDetails && phaseListDetails?.form_details?.length > 0) {
      const multiSelectData = phaseListDetails?.form_details?.map((item) => ({
        id: item?.id,
        name: item?.type?.name,
      }));
      setExportModelData((prevState) => ({
        ...prevState,
        children: prevState.children.map((child, index) =>
          index === 0 ? { ...child, data: multiSelectData || [] } : {}
        ),
      }));
      setOpenExportModal(true);
    } else {
      dispatch(
        setSnackbar({
          value: true,
          message: "No Form Assigned",
          color: "error",
        })
      );
    }
  };

  const organizeDataByKey = (arr) => {
    const organized = arr.reduce((acc, str) => {
      const [formId, questionId] = str.split("__-=");

      if (!acc[formId]) {
        acc[formId] = {
          id: parseInt(formId),
          questions: [],
        };
      }

      acc[formId].questions.push(questionId);

      return acc;
    }, {});

    // Convert the organized data to an array
    return Object.values(organized);
  };

  // Function for handle update patient id
  const handleSavePatientId = async (data) => {
    setEditIdLoading(true);
    const formData = {
      subject_id: editSubject,
      patient_id: data.patientId,
    };

    SubjectApi.editPatientId(formData)
      .then((res) => {
        if (res.status == 200 && res.success == true) {
          setEditIdLoading(false);
          setOpenEditSubjectIdModal(false);
          dispatch(
            setSnackbar({
              value: true,
              message: "Patient Id Updated Successfully!",
              color: "success",
            })
          );
        } else {
          setEditIdLoading(false);
          dispatch(
            setSnackbar({
              value: true,
              message: res?.message,
              color: "error",
            })
          );
        }

        getSubjectList(); // calling function to load patient list
      })
      .catch((e) => {
        setEditIdLoading(false);
        setOpenEditSubjectIdModal(false);
        dispatch(
          setSnackbar({
            value: true,
            message: "Patient Id Updatedtion Failed, Try later!",
            color: "error",
          })
        );
      });
  };

  const handleExportModel = async (val) => {
    const updatedformQsList = Array.isArray(formQsList)
      ? formQsList.map((item) => ({
          ...item,
          questions: Array.isArray(item.questions)
            ? item.questions.map((el) => el.id)
            : [],
        }))
      : [];

    try {
      setExportLoading(true);
      const res = await PhaseApi.exportFormCsv(
        phaseListDetails?.id,
        val?.formId?.length === 1
          ? updatedformQsList
          : organizeDataByKey(val?.questionId)
      );
      if (res?.data && !isEmpty(res?.data)) {
        const csvData = jsonToCsv(res?.data);
        downloadCsv(
          csvData,
          `${phaseListDetails?.product_details?.name}_${phaseListDetails?.title}.csv`
        );
        setOpenExportModal(false);
      } else {
        dispatch(
          setSnackbar({
            value: true,
            message: "No Patient Data Found",
            color: "error",
          })
        );
      }
    } catch (error) {
      console.log("Export Patient Error:", error);
    } finally {
      setExportLoading(false);
    }
  };

  const handleExportSelect = async (val, name) => {
    try {
      if (name === "formId") {
        if (val?.length >= 1) {
          const res = await PhaseApi.getQuestionsByFormTemplate(
            phaseListDetails?.id,
            val
          );
          if (val?.length === 1) {
            setFormQsList(res.data || []);
          }
          if (val?.length > 1) {
            if (res && res.data) {
              const transformedData = res.data?.map((form) => ({
                ...form,
                questions: form.questions?.map((question) => ({
                  ...question,
                  id: `${form.id}__-=${question.id}`, // Append form ID with separator
                })),
              }));

              // Update the state with the transformed data
              setExportModelData((prevState) => {
                const updatedChildren = prevState.children.map((child) =>
                  child.name === "questionId"
                    ? { ...child, data: transformedData, disabled: false } // Update existing entry
                    : child
                );

                if (
                  !prevState.children.some(
                    (child) => child.name === "questionId"
                  )
                ) {
                  // If 'questionId' doesn't exist, add it
                  updatedChildren.push({
                    type: "multidropdown",
                    name: "questionId",
                    label: "Select Questions",
                    placeholder: "Select Questions",
                    data: transformedData,
                    IsRequired: true,
                    disabled: false,
                    showSelectAll: true,
                  });
                }

                return {
                  ...prevState,
                  children: updatedChildren,
                };
              });
            } else {
              console.log("No data returned from API");
            }
          }
        }
        if (val?.length <= 1) {
          setExportModelData((prevState) => ({
            ...prevState,
            children: prevState.children.filter(
              (child) => child.name !== "questionId"
            ),
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePatientCycleConfrim = (confirmation) => {
    if (!confirmation) {
      setPatientFormValues({});
      setPatientCycleConfirm(false);
      setOpenAddSubjectModal(false);
      dispatch(
        setSnackbar({
          value: true,
          message: "Duplicate record not created.",
          color: "error",
        })
      );
      return;
    }
    setPatientCycleLoading(true);
    handleAddSubject(patientFormValues, confirmation);
    setPatientFormValues({});
    setPatientCycleLoading(false);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc"; // On same column click change order
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const resetSortFilter = () => {
    if (order == "desc") {
      setOrder("asc");
    }
    if (orderBy) {
      setOrderBy("");
    }
  };

  useEffect(() => {
    if (orderBy) {
      getSubjectList();
    }
  }, [orderBy, order]);

  return (
    <div className=" p-3 sm:p-4">
      {patientCycleConfirm ? (
        <ConfirmationModal
          open={patientCycleConfirm}
          setOpen={setPatientCycleConfirm}
          handleConfirmation={handlePatientCycleConfrim}
          errMessage={patientCycleErrMess}
          isLoading={patientCycleLoading}
        />
      ) : null}              
      {openAddSubjectModal ? (
        <CustomModel
          rowId={rowId}
          modalInitialValues={rowId ? isSwap? initialValues :patientEdit : AddNewSubjectPhase}
          initialValuesValidationSchema={
            rowId? isSwap?  ReAssignValidationSchema: editSubjectPhaseValidationSchema :addNewSubjectPhaseValidationSchema           
          }
          open={openAddSubjectModal}
          setOpen={setOpenAddSubjectModal}
          setModelData={setSubjectModelData}
          modelData={subjectModelData}
          handleModelValues = {rowId ? (isSwap ? handleOpenAssignPiAndEditorModal : handleEditSubjectSubmit) 
  : handleAddSubject}

          isLoadingBtn={rowId ? assigningPiLoader : isAddSubjectLoading}
          onChange={getEditorDropdown}
        />
      ) : (
        <>
          {/* InitialPhase of modal check */}
          {initialPhaseModal ? (
            <CustomModel
              modalInitialValues={AddPhase}
              initialValuesValidationSchema={addPhaseValidationSchema}
              open={initialPhaseModal}
              setOpen={setInitialPhaseModal}
              setModelData={setModelData}
              modelData={modelData}
              handleModelValues={handleAddVisitNumber}
              isLoadingBtn={isAddPrLoading}
              onChange={handleTemplateList}
            />
          ) : null}
          <CustomPhaseModel
            // initialValuesValidationSchema={visitFrequencyValidationSchema}
            open={openAddPhaseModal}
            setOpen={setOpenAddPhaseModal}
            setModelData={setVisitFrequencyModelData}
            modelData={visitFrequencyModelData}
            handleModelValues={templateList}
            setModelValues={setTemplateList}
            onChange={handleTemplateList}
            handleVisitFrequency={handleVisitFrequency}
            setSecondPhaseModal={setInitialPhaseModal}
            setTemplateTypeIds={setTemplateTypeIds}
            getTemplateId={getTemplateTypeIds}
            setSelectFormType={setSelectFormType}
            selectFormType={selectFormType}
            page={page}
            setPage={setPage}
            maxPages={maxPages}
            loadMoreData={loadMoreData}
            setIsAddPhaseLoading={setIsAddPhaseLoading}
            setIsTrialInfoLoading={setIsTrialInfoLoading}
            setCount={setCount}
            count={count}
            // loading={loading}
          />

          <CustomModel
            modalInitialValues={visitNumberIntialValue}
            initialValuesValidationSchema={() =>
              addVisitTypeNumValidationSchema(visitNumberModelData)
            }
            open={openVisitNumberModel}
            setOpen={setOpenVisitNumberModel}
            setModelData={setVisitNumberModelData}
            modelData={visitNumberModelData}
            handleModelValues={handleAddVisitNumber}
            isLoadingBtn={isAddPrLoading}
            onChange={handleTemplateList}
          />
        </>
      )}

      {openExportModal ? (
        <CustomModel
          rowId={rowId}
          modalInitialValues={exportFormCsv}
          initialValuesValidationSchema={addFormExportSchema}
          open={openExportModal}
          setOpen={setOpenExportModal}
          setModelData={() => {}}
          modelData={exportModelData}
          handleModelValues={handleExportModel}
          isLoadingBtn={exportLoading}
          onChange={handleExportSelect}
          loader={exportLoading}
        />
      ) : null}

      {/* Modal added for edit subject id */}

      {openEditSubjectIdModal ? (
        <CustomModel
          rowId={rowId}
          modalInitialValues={patientEditId}
          initialValuesValidationSchema={validateEditSubjectId}
          open={openEditSubjectIdModal}
          setOpen={setOpenEditSubjectIdModal}
          setModelData={() => {}}
          modelData={defaultEditSubjectId}
          handleModelValues={handleSavePatientId}
          isLoadingBtn={editIdLoading}
          loader={editIdLoading}
        />
      ) : null}

      {/* breadcrumb */}
      <div className="mb-0 md:mb-4">
        <BreadCrumb
          BreadCrumbsListing={[
            { name: "Products", path: "products" },
            {
              name: `${productDetails?.name || ""} Details`,
              path: location.pathname,
            },
          ]}
        />
      </div>
      <div className="mt-3 md:mt-5">
        <TaskManagementHeader
          SearchbarPlaceholder="Search by Name"
          width={"100%"}
          ButtonText="Add Phase"
          ButtonAuth={{ module: "trials", auth: 10 }}
          handleButtonClick={handleAddPhaseModal}
          handleSearch={handlePhaseSearch}
          showExportButton={role_id === 1 || role_id === 4}
          handleExportButton={handleExportButton}
        />
      </div>
      {loading || (phaseListData && phaseListData?.length > 0) ? (
        <div className="flex flex-col lg:grid grid-cols-12 gap-4 pt-3">
          <div className="col-start-1 col-end-4 w-100 phase-list-card">
            {phaseListLoading ? (
              <div className="col-start-1 col-end-4 ">
                <SkeletonLoader rowCount={2} isTable={false} />
              </div>
            ) : (
              phaseListData?.map((item) => (
                <CustomChipCard
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  setCurrentpage={setCurrentpage}
                  resetSortFilter={resetSortFilter}
                  data={{
                    id: item?.id,
                    title: item?.title
                      ? `${item?.product_details?.name}:  ${item?.title}`
                      : "",
                    badglesValue: {
                      statusBadglesValue:
                        item?.phase_status_details?.status || "",
                      typeBadglesValue: item?.phase_type_details?.name || "",
                    },
                  }}
                  key={item?.id}
                />
              ))
            )}
          </div>

          <div className="col-start-4 col-end-13 min-h-[25rem] bg-[#FFFFFF] rounded-xl">
            {phaseDetailLoading || phaseListLoading ? (
              <SkeltonLoaderHeader
                rowCount={2}
                isTable={false}
                width={200}
                height={30}
              />
            ) : (
              <CustomWrapperCard
                editDeleteBtn={true}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                data={phaseListDetails || {}}
                loading={isAddPhaseLoading}
              />
            )}
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Description"}
              ShowHeaderButton={false}
              loading={isTrialInfoLoading}
            >
              {phaseDetailLoading || phaseListLoading ? (
                <SkeletonLoader
                  rowCount={1}
                  isTable={false}
                  width={500}
                  height={20}
                />
              ) : (
                <Typography variant="p" className="break-words">
                  {phaseListDetails?.description?.[0]?.toUpperCase() +
                    phaseListDetails?.description?.slice(1) ||
                    "No Description Added"}
                </Typography>
              )}
            </TrialInfoComponent>

            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Assigned Forms"}
              ShowHeaderButton={false}
              loading={isTrialInfoLoading}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={2}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.form_details?.length > 0
                      ? phaseListDetails?.form_details?.map((item) => (
                          <CustomDetailComponent
                            key={item?.id || ""}
                            BgColor="#2831720D"
                            FirstHeading={item.title || ""}
                            Icon={<DocumentIcon LightBlue={true} />}
                            ShowDeleteIcon={false}
                          />
                        ))
                      : "No Assigned Form Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />
            <TrialInfoComponent
              HeaderTitle={"Principal Investigator"}
              ShowHeaderButton={false}
              loading={isTrialInfoLoading}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={3}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.investigator_details
                      ?.principal_investigator?.length > 0
                      ? phaseListDetails?.investigator_details?.principal_investigator?.map(
                          (item) => (
                            <div key={item?.id} className="w-full sm:w-max">
                              <CustomDetailComponent
                                BgColor="#2831720D"
                                FirstHeading={item?.name || ""}
                                SecondHeading={
                                  item?.hospital_details?.name || ""
                                }
                                Icon={<InvestigatorIcon />}
                                ShowDeleteIcon={false}
                              />
                            </div>
                          )
                        )
                      : "No Principal Investigator Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Quality Associate"}
              ShowHeaderButton={false}
              loading={isTrialInfoLoading}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={3}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.immunoactQA_details?.length > 0
                      ? phaseListDetails?.immunoactQA_details?.map((item) => (
                          <div key={item?.id} className="w-full sm:w-auto">
                            <CustomDetailComponent
                              BgColor="#2831720D"
                              FirstHeading={item?.name || ""}
                              Icon={<PersonSvg />}
                              ShowDeleteIcon={false}
                            />
                          </div>
                        ))
                      : "No Quality Associate Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />

            <TrialInfoComponent
              HeaderTitle={"Clinical Phase Manager"}
              ShowHeaderButton={false}
              loading={isTrialInfoLoading}
            >
              <div className="flex gap-2 justify-start flex-wrap">
                {phaseDetailLoading || phaseListLoading ? (
                  <SkeletonLoader
                    rowCount={3}
                    isTable={false}
                    width={200}
                    height={30}
                  />
                ) : (
                  <>
                    {phaseListDetails?.trial_manager_details?.length > 0
                      ? phaseListDetails?.trial_manager_details?.map((item) => (
                          <div key={item?.id} className="w-full sm:w-auto">
                            <CustomDetailComponent
                              BgColor="#2831720D"
                              FirstHeading={item.name}
                              Icon={<PersonSvg />}
                              ShowDeleteIcon={false}
                            />
                          </div>
                        ))
                      : "No Clinical Phase Manager Found."}
                  </>
                )}
              </div>
            </TrialInfoComponent>
            <hr className="ml-[1rem] mr-[1rem]   " />
            <div className="p-3">
              <CustomTable
                SearchbarBgColor="secondary"
                rows={subjectData?.rows}
                columnAlign={subjectData?.columnAlign}
                columns={subjectData?.columns}
                handleTableFilters={handleTableFilters}
                tableFilters={tableFilters}
                handleChangePage={handlePagination}
                handleSwapButton={handleSwapButton}
                handleEditButton={handleEditSubject}
                handleEditSubjectId={handleEditSubjectid}
                loading={loading}
                currentPage={currentPage}
                totalpage={totalPage}
                TableData={{
                  ...subjectData?.TableSearchActions,
                  filters: subjectData?.TableSearchActions?.filters?.map(
                    (item) => {
                      if (item?.id === "editorList") {
                        return {
                          ...item,
                          MenuList: editorList,
                          dropDownValue: editorId,
                        };
                      } else if (item?.id === "selectPI") {
                        return {
                          ...item,
                          MenuList: pIList,
                          dropDownValue: piId,
                        };
                      }
                      return item;
                    }
                  ),
                }}
                order={order}
                orderBy={orderBy}
                EnableSorting={true}
                handleSort={handleSort}
                handleChangeDropDown={handleDropDownChange}
                handleNavigate={handleNavigateToDetails}
                handleTableDataButton={handleOpenAddSubjectModal}
                DropdownBgColor={theme?.palette?.grey[200]}
                disableButton={disableButton}
              />
            </div>
          </div>
        </div>
      ) : phaseListData !== null ? (
        <div className="mt-4">
          <NoDataCard />
        </div>
      ) : null}
      <DeleteConfirmContent
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={handleDeletePhase}
        isLoading={isDeleteLoading}
      />
      <CustomSnackbar
        open={snackbar?.value}
        setOpen={setSnackbar}
        color={snackbar?.color}
        message={snackbar?.message}
      />
      <AddPiAndEditorForAllModalContent
        open={openAssignForAllModal}
        setOpen={setOpenAssignForAllModal}
        handleClick={SumbitAssignAllPiAndEditor}
        isLoading={assigningPiLoader}
      />
    </div>
  );
};
