import { useSelector } from "react-redux";
import dashboard from "../menu-items/dashboard";

export const authorities = {
  form: {
    operations: [
      {
        id: 1,
        name: "Able to view form",
        label: "Form Module",
      },
      {
        id: 2,
        name: "Able to create form",
        label: "Create Form",
      },
      {
        id: 3,
        name: "Able to edit form",
        label: "Edit Form",
      },
      {
        id: 4,
        name: "Able to delete form",
        label: "Delete Form",
      },
      {
        id: 5,
        name: "Able to clone form",
        lable: "Clone Form",
      },
      {
        id: 7,
        name: "Able to view form tamplate preview",
        lable: "Form Builder Preview",
      },
    ],
  },
  trials: {
    operations: [
      {
        id: 9,
        label: "Phase Module",
        name: "Able to view phase module",
      },
      {
        id: 10,
        label: "Add Phase",
        name: "Able to add details of phases",
      },
      {
        id: 11,
        label: "Edit Phase Details",
        name: "Able to edit details of phases",
      },
      {
        id: 12,
        label: "Delete Phase",
        name: "Able to delete phases",
      },
      {
        id: 13,
        label: "Assign PI and Editor to Phase",
        name: "Able to assign PI and editor to a phase",
      },
      {
        id: 14,
        label: "Details Phase",
        name: "Able to view phase details",
      },
    ],
  },
  subject: {
    operations: [
      {
        id: 15,
        label: "Patient Module",
        name: "Able to View Patient Module",
      },
      {
        id: 16,
        label: " Add Patient",
        name: "Able to Add Patient",
      },
      {
        id: 17,
        label: "Patient Details",
        name: "Able to View Patient Setails",
      },
      {
        id: 18,
        label: "Delete Patient",
        name: "Able to Delete Patient",
      },
      {
        id: 19,
        label: "Update Patient Status",
        name: "Able to Update Patient Status",
      },
      {
        id: 20,
        label: "Reassign PI and Editor to Patient",
        name: "Able to reassign PI and Editor to Patient",
      },
    ],
  },
  editorListing: {
    operations: [
      {
        id: 21,
        label: "Assigned Editors Module",
        name: "Able to view editor listing module",
      },
      {
        id: 22,
        label: "Details editor",
        name: "Able to view details of editor",
      },
      {
        id: 23,
        label: "Details Patient",
        name: "Able to view details of Patient",
      },
      {
        id: 24,
        label: "Add editor",
        name: "Able to add editor",
      },
    ],
  },
  trialManage: {
    operations: [
      {
        id: 27,
        label: "Phase Management module",
        name: "Able to manage Phase module",
      },
      {
        id: 28,
        label: "Phase Resend Requests",
        name: "Able to resend requests",
      },
      {
        id: 29,
        label: "Phase Accept Reject Requests",
        name: "Able to accept reject requests",
      },
      {
        id: 30,
        label: "Phase Details",
        name: "Able to manage requests",
      },
      {
        id: 31,
        label: "Form Details",
        name: "Able to view form",
      },
    ],
  },
  hospitalManage: {
    operations: [
      {
        id: 32,
        label: "Add Hospital Module",
        name: "Able to manage hospital module",
      },
      {
        id: 33,
        label: "Add new hospital management",
        name: "Able to add hospital management",
      },
      {
        id: 34,
        label: "Edit hospital management",
        name: "Able to edit hospital management",
      },
      {
        id: 35,
        label: "Delete hospital management",
        name: "Able to delete hospital management",
      },
    ],
  },
  principalInvestigator: {
    operations: [
      {
        id: 40,
        label: "Principal Investigator Module",
        name: "Able to manage principal investigator module",
      },
      {
        id: 41,
        label: "Add principal Investigator",
        name: "Able to add principal investigator",
      },
      {
        id: 42,
        label: "Edit principal Investigator",
        name: "Able to edit principal investigator",
      },
      {
        id: 43,
        label: "Delete principal Investigator",
        name: "Able to delete principal investigator",
      },
      {
        id: 44,
        label: "Detail principal Investigator",
        name: "Able to view details of principal investigator",
      },
      {
        id: 45,
        label: "Reasign principal Investigator to editor",
        name: "Able to reasign principal investigator to editor",
      },
      {
        id: 46,
        label: "Editor List",
        name: "Able to view editor list for investigator",
      },
    ],
  },
  userManagement: {
    operations: [
      {
        id: 49,
        label: "Onboard Users Module",
        name: "Able to manage user module",
      },
      {
        id: 50,
        label: "Add User",
        name: "Able to add user",
      },
      {
        id: 51,
        label: "Edit User",
        name: "Able to edit user",
      },
      {
        id: 52,
        label: "Delete User",
        name: "Able to delete user",
      },
      {
        id: 53,
        label: "Detail User",
        name: "Able to view details of user",
      },
    ],
  },
  editorManage: {
    operations: [
      {
        id: 58,
        label: "Editor Management Module",
        name: "Able to manage editor module",
      },
      {
        id: 59,
        label: "Add Editor Manage",
        name: "Able to add editor management",
      },
      {
        id: 60,
        label: "Edit Editor Manage",
        name: "Able to edit editor management",
      },
      {
        id: 61,
        label: "Delete Editor Manage",
        name: "Able to delete editor management",
      },
      {
        id: 62,
        label: "Detail Editor Manage",
        name: "Able to view details of editor management",
      },
    ],
  },
  diseaseManage: {
    operations: [
      {
        id: 67,
        label: "Add Disease Module",
        name: "Able to manage disease module",
      },
      {
        id: 68,
        label: "Add New Disease Manage",
        name: "Able to add disease management",
      },
      {
        id: 69,
        label: "Edit Disease Manage",
        name: "Able to edit disease management",
      },
      {
        id: 70,
        label: "Delete Disease Manage",
        name: "Able to delete disease management",
      },
      {
        id: 71,
        label: "Detail Disease Manage",
        name: "Able to view details of disease management",
      },
    ],
  },
  product: {
    operations: [
      {
        id: 77,
        label: "Product Module",
        name: "Able to manage product module",
      },
      {
        id: 78,
        label: "Add product",
        name: "Able to add product",
      },
      {
        id: 79,
        label: "Detail product",
        name: "Able to view details of product",
      },
      {
        id: 80,
        label: "Edit product",
        name: "Able to edit product",
      },
      {
        id: 81,
        label: "Detete product",
        name: "Able to delete product",
      },
    ],
  },
  editor: {
    operations: [
      {
        id: 88,
        label: "Editor Module",
        name: "Able to manage editor module",
      },
      {
        id: 89,
        label: "Details editor",
        name: "Able to view details of editor",
      },
      {
        id: 90,
        label: "Details Patient",
        name: "Able to View Details of Patient",
      },
    ],
  },
  rolesManagement: {
    operations: [
      {
        id: 95,
        label: "Roles Management",
        name: "Able to manage roles",
      },
      {
        id: 96,
        label: "Add role",
        name: "Able to add role",
      },
      {
        id: 97,
        label: "Edit role",
        name: "Able to edit role",
      },
      {
        id: 98,
        label: "Details roles",
        name: "Able to view details of roles",
      },
      {
        id: 99,
        label: "Delete roles",
        name: "Able to view delete of roles",
      },
      {
        id: 100,
        label: "Assign Permissions to roles",
        name: "Able to assign permissions to roles",
      },
    ],
  },
  trialManager: {
    operations: [
      {
        id: 103,
        label: "Phase Manager",
        name: "Able to manage Phase manager",
      },
      {
        id: 104,
        label: "Add Phase Manager",
        name: "Able to add Phase manager",
      },
      {
        id: 105,
        label: "Edit Phase Manager",
        name: "Able to edit Phase manager",
      },
      {
        id: 106,
        label: "Details Phase Manager",
        name: "Able to view details of Phase manager",
      },
      {
        id: 107,
        label: "Delete Phase Manager",
        name: "Able to view delete of Phase manager",
      },
    ],
  },
  imunoactQAManager: {
    operations: [
      {
        id: 109,
        label: "ImunoactQA Manager",
        name: "Able to manage imunoactQA manager",
      },
      {
        id: 110,
        label: "Create ImunoactQA Manager",
        name: "Able to add imunoactQA manager",
      },
      {
        id: 111,
        label: "Edit ImunoactQA Manager",
        name: "Able to edit imunoactQA manager",
      },
      {
        id: 112,
        label: "Details ImunoactQA Manager",
        name: "Able to view details of imunoactQA manager",
      },
      {
        id: 113,
        label: "Delete ImunoactQA Manager",
        name: "Able to view delete of imunoactQA manager",
      },
    ],
  },
  appointmentManager: {
    operations: [
      {
        id: 121,
        label: "Appointment Module",
        name: "Able to view appointment details",
      },
      {
        id: 122,
        label: "Create Appointment",
        name: "Able to add appointment",
      },
      {
        id: 123,
        label: "Save Appointment Answer Data",
        name: "Able to save appointment answer data",
      },
      {
        id: 124,
        label: "Approve Appointment Answer",
        name: "Able to approve appointment answer",
      },
      {
        id: 125,
        label: "Add Patient Id",
        name: "Able to add patient id",
      },
      {
        id: 126,
        label: "Patient Life's Status",
        name: "Able to select patient life status",
      },
    ],
  },
  queryManager: {
    operations: [
      {
        id: 127,
        label: "Query Module",
        name: "Able to manage user manager",
      },
      {
        id: 128,
        label: "Create Query",
        name: "Able to add appointment",
      },
      {
        id: 129,
        label: "Query History",
        name: "Able to view query history",
      },
      {
        id: 130,
        label: "Resolve Query",
        name: "Able to resolve query",
      },
    ],
  },
  auditHistory: {
    operations: [
      {
        id: 140,
        label: "Audit History Module",
        name: "Able to view audit history module",
      },
    ],
  },
  formTypeManage: {
    operations: [
      {
        id: 147,
        label: "Form Type Manage",
        name: "Able to manage form types",
      },
      {
        id: 148,
        label: "Form Type Manage",
        name: "Able to add form types",
      },
      {
        id: 149,
        label: "Form Type Manage",
        name: "Able to edit form types",
      },
      {
        id: 150,
        label: "Form Type Manage",
        name: "Able to delete form types",
      },
    ],
  },
  subject_pending: {
    operations: [
      {
        id: 160,
        label: "Update Patient Id",
        name: "Able to update patient id",
      },
      {
        id: 161,
        label: "Update Patient",
        name: "Able to update patient ",
      },
    ],
  },
};

export const defaultModulevalues = {
  dashboard: 1,
  product: 2,
};

export const masterDataAuth = [1, 21, 95, 67, 58, 49, 40, 32];
export const roles = {
  admin: [
    1, 2, 3, 4, 5, 6, 7, 17, 27, 32, 33, 34, 35, 40, 41, 42, 43, 44, 49, 50, 51,
    52, 53, 58, 59, 60, 61, 62, 67, 68, 69, 70, 71, 77, 0, 78, 79, 80, 81,
  ],
  editor: [88, 89, 17],
  pi: [9, 10, 15, 16, 17, 21, 22, 23],
  coodinatorPi: [9, 10, 15, 16, 17, 21, 22, 23, 27],
};
export const role = "admin";

/**
 * checks weather user has access and the operation also exists
 * @param {*} module
 * @param {*} userAuthority
 * @param {*} operationId
 * @returns true if user has access
 */

export const getUserHasAccess = (userAuthority, module, operationId) => {
  const authOperationIds =
    authorities[module]?.operations?.map((item) => item.id) || [];
  let has_access = false;
  if (
    authOperationIds.includes(operationId) &&
    userAuthority.includes(operationId)
  ) {
    has_access = true;
  }
  return has_access;
};

/**
 *
 * @returns true if user has authority for that component
 */

export const hasAuthority = (authorities = [], userAuthority) => {
  const user_authority = userAuthority;
  // Check if any of the specified authorities are in user_authority
  return authorities.some((element) => user_authority.includes(element));
};

/**
 *
 * @returns true if user has role for that component
 */

export function findUrlByAuthority(per) {
  // Function to search through an array of items
  function searchItems(items) {
    for (let item of items) {
      if (item.authorities && item.authorities.includes(per)) {
        return item.url;
      }
    }
    return null;
  }

  // Search in children
  let url = searchItems(dashboard.children);
  if (url) return url;

  // Search in subChildren
  return searchItems(dashboard.subChildren);
}

export function checkMasterData() {
  // Iterate over the subChildren array
  const LoginData = useSelector((state) => state.loginData);
  const user_authority = LoginData?.auth || [];
  for (let subChild of dashboard.subChildren) {
    // Check if any of the module_ids exist in the subChild's authorities array
    for (let id of user_authority) {
      if (id != 1000) {
        if (subChild.authorities && subChild.authorities.includes(id)) {
          return true;
        }
      }
    }
  }
  return false;
}

export function findDashboardByPermission(permissionArray) {
  const dashboardItems = [];
  // Iterate through children and subChildren
  for (const item of dashboard.children) {
    if (
      item.authorities &&
      !item.subChildren &&
      item.authorities?.some((auth) =>
        permissionArray?.some((perm) => perm.module_id === auth)
      )
    ) {
      dashboardItems.push(item);
    }
    if (item.subChildren) {
      for (const childItem of item.subChildren) {
        // Check if authorities exist and if any of the permission module_ids match any of the authorities
        if (
          childItem.authorities &&
          childItem.authorities?.some((auth) =>
            permissionArray?.some((perm) => perm.module_id === auth)
          )
        ) {
          dashboardItems.push(childItem);
        }
      }
    }
  }
  return dashboardItems;
}

export const hasCheckedModulesAuth = () => {
  Object.values(authorities).map((module) => module.operations[0]);
};
