import React from 'react';
import { setSnackbar } from "../../store/reducers/snackBar";
import { useDispatch, useSelector } from "react-redux";
import { CustomSnackbar } from "../../components/snackbar/CustomSnackbar";
import { addFormType } from "../../utils/formik/initialValues";
import DeleteConfirmContent from "../../components/modal-contents/DeleteConfirmModalContent";
import Loadable from "../../components/Loadable";
const CustomTable = Loadable(React.lazy(() => import('../../components/custom-table/CustomTable')));
const CustomModel = Loadable(React.lazy(() => import('../../components/custom-model/CustomModel')));
import { FormTypeManagementTableData } from "../../utils/tableData";
import { addFormTypeSchema } from "../../utils/formik/validationSchema";
import FormTypeManagementAPI from '../../services/formTypeService';


// @info : function for combine formtype data to display into table 
const convertFormTypeData = (data) => {
    return data.map((types, key) => {  
        let formTypeName = types?.name;
        let formTypeId = types?.id;
        let isPredefined = types?.is_predefined;
        let prefix = types?.prefix;


        return [key+1, prefix,formTypeName ,isPredefined, formTypeId];
    });
}


export const FormTypeManagement = () => {

    const dispatch = useDispatch()
    const snackbar = useSelector((state) => state.snackBar);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState(addFormType);
    const [currentPage, setCurrentpage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);
    const [formTypeData, setFormTypeData] = React.useState(FormTypeManagementTableData);
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = React.useState(false);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [openEditTypeModal, setOpenEditTypeModal] = React.useState(false);
    const [deleteRowData, setDeleteRowData] = React.useState(null)
    const [rowId, setRowId] = React.useState(null)
    const [modelData, setModelData] = React.useState({
        title: "Add New Form Type",
        editTitle: "Edit Form Type",
        children: [
            { type: "input", name: "prefix", label: "Acronym", placeholder: "Add Acronym", IsRequired: true },
            { type: "input", name: "typeName", label: "Type Name", placeholder: "Add Type Name", IsRequired: true }
        ]
    })

    const handleOpenAddModal = () => {
        setInitialValues(addFormType)
        setRowId("")
        setOpenAddModal(true);
    }

    const getFormTypesList = () => FormTypeManagementAPI.getAllFormTypes(10, currentPage, '')
        .then((res) => {
            let rowData = convertFormTypeData(res?.data || [])
            setFormTypeData(prev => ({
                ...prev,
                rows: [...rowData]
            }));
            setTotalPage(Math.ceil(res?.page_details?.no_of_records / res?.page_details?.limit));
            setCurrentpage(res?.page_details?.page);
            setLoading(false);
        }
        )
        .catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setLoading(false);
        })

    const handleOpenDeleteConfirmModal = (row) => {
        if(row[3] === 0) {
            setOpenDeleteConfirmModal(true);
            setDeleteRowData(row)
        } else {
            dispatch(setSnackbar({ value: true, message: "You can't delete this form type.",  color: "error" }));
        }
    
    }

    const handleOpenEditFormTypeModal = (row) => {        
        setRowId(row[4])
        setInitialValues({
            prefix: row[1],
            typeName: row[2],
        })
        setOpenEditTypeModal(true);
    }

    const handleAddModelValues = (values) => {
        setSubmitLoading(true);
        FormTypeManagementAPI.addFormType({
            prefix:values?.prefix,
            name: values?.typeName
        }).then((res) => {
            if (res.status === 200) {
                setOpenAddModal(false)
                setSubmitLoading(false);
                getFormTypesList()
                dispatch(setSnackbar({ value: true, message: res.message,  color: "success" }));
            }
        }).catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSubmitLoading(false);
        })
    }

    // Edit function for update values 
    const handleEditModelValues = (values) => {
        setSubmitLoading(true);
        FormTypeManagementAPI.updateFormType({
            prefix: values?.prefix,
            name: values?.typeName
        }, rowId).then((res) => {
            if (res.status === 200) {
                setOpenEditTypeModal(false)
                setSubmitLoading(false);
                getFormTypesList()
                dispatch(setSnackbar({ value: true, message: res.message,color: "success" }));
            }
        }).catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSubmitLoading(false);
        })
    }

    const handlePagination = (value) => {
        setCurrentpage(value);
    };

    const handleDeleteRow = () =>{ 
        setSubmitLoading(true);
        setOpenDeleteConfirmModal(false)
        FormTypeManagementAPI.deleteFormType(deleteRowData[4]).then((res) => {
            if (res?.status === 200) {
                setSubmitLoading(false);
                getFormTypesList()
                dispatch(setSnackbar({ value: true, message: res.message, color: "success" }));
            }
        }).catch((error) => {
            dispatch(setSnackbar({ value: true, message: error, color: "error" }));
            setSubmitLoading(false);
        })
    }


    React.useEffect(() => {
        
        setLoading(true)
        getFormTypesList();
        
    }, [currentPage])

    
    return <>
        <div className="flex flex-col gap-0 bg-white p-0 !pb-0">
            <CustomModel
                rowId={rowId}
                modalInitialValues={initialValues}
                initialValuesValidationSchema={addFormTypeSchema}
                open={openEditTypeModal ? openEditTypeModal : openAddModal}
                setOpen={openEditTypeModal ? setOpenEditTypeModal : setOpenAddModal}
                setModelData={setModelData}
                modelData={modelData}
                handleModelValues={openEditTypeModal ? handleEditModelValues : handleAddModelValues}
                isLoadingBtn={submitLoading}
            />
            <CustomTable
                rows={formTypeData?.rows}
                columnAlign={formTypeData?.columnAlign}
                columns={formTypeData?.columns}
                TableData={formTypeData?.TableData}
                handleButtonClick={handleOpenAddModal}
                handleEditButton={handleOpenEditFormTypeModal}
                handleDeleteButton={handleOpenDeleteConfirmModal}
                handleChangePage={handlePagination}
                loading={loading}
                currentPage={currentPage}
                totalpage={totalPage}
            />
        </div>
        <DeleteConfirmContent open={openDeleteConfirmModal} setOpen={setOpenDeleteConfirmModal} handleDelete={handleDeleteRow} isLoading={submitLoading} />
        <CustomSnackbar open={snackbar?.value} setOpen={setSnackbar} color={snackbar?.color} message={snackbar?.message} />

    </>
}