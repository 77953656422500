import React from "react";
import { BackNavIcon } from "../../assets/common-svg/CommonSvg";
import { useLocation, useNavigate } from "react-router";
import { Typography, useTheme } from "@mui/material";
import FormTemlateApi from "../../services/form-template";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";
import { clearTemplateValues } from "../../store/reducers/form-template";

// formdata

const BreadCrumb = ({BreadCrumbsListing, formData}) => {
  const dispatch = useDispatch();
  const FormTempleteData = useSelector((state) => state.formTemplete);
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  let currentpath = location.pathname;
  let id = currentpath.length > 0 ? currentpath.split('/')[3] : 0;
  let pathName = currentpath.length > 0 ? currentpath.split('/')[2] :'';

  React.useEffect(() => {
    // Your logic here to react to changes in state or path
    const { state } = location;
    console.log(state); // Do something with the updated state
  }, [location]);


  const NavigateAndUpdateNavigationState = (path, index) => {
    if(pathName==='update-form-builder'){
      updateForm(formData, id)
    }
    let UpdatedStateArray = location.state;
    UpdatedStateArray = UpdatedStateArray?.slice(0, index + 1);
    navigate(path, { state: UpdatedStateArray },true)
  };

  const updateForm = (formDatas,rowId) => {
    let data = {
        title: FormTempleteData?.title,
        description: FormTempleteData?.description,
        type_id: FormTempleteData?.type,
        status: 'Saved as Draft',
        questions: JSON.stringify(formDatas),
    };
    FormTemlateApi.updateFormTemplate(data, rowId)
        .then((res) => {
            if (res.status === 200) {
                dispatch(clearTemplateValues());
            }
        })
        .catch((err) => {
            dispatch(setSnackbar({ value: true, message: err, color: "error" }));
        });
};

  const handleBackNav = () => {
    let UpdatedStateArray = location?.state;
    if(pathName==='update-form-builder'){
      updateForm(formData, id);
    }
    if(UpdatedStateArray){
      UpdatedStateArray?.pop();
      navigate(UpdatedStateArray[(UpdatedStateArray?.length || 0) - 1]?.path, {
        state: UpdatedStateArray,
      }, true);
    } else if(BreadCrumbsListing){
      const lastPath = BreadCrumbsListing[BreadCrumbsListing.length-2]?.path;
      if(BreadCrumbsListing[BreadCrumbsListing.length-2]?.path[0] === '/'){
        navigate(lastPath)
      }
      else{
        navigate(`/${lastPath}`)
      }
    }
    else{
      navigate(-1);
    }
  };



  return (
    <div className="flex items-center sm:gap-[7px]">
      <div className="cursor-pointer mr-1 sm:mr-3" onClick={handleBackNav}>
        <BackNavIcon />
      </div>
     
      {location.state && location.state.length>0 ?
        location.state?.map((data, index) => {
          return (
            <div key={index} className="flex items-center">
              {index !== location.state?.length - 1 ? (
                <>
                  {" "}
                  <Typography
                    fontWeight={theme.typography.fontWeightRegular}
                    variant="h6"
                    className="cursor-pointer text-xs sm:!text-base capitalize"
                    color="secondary"
                    onClick={() =>
                      NavigateAndUpdateNavigationState(data.path, index)
                    }
                  >
                    {data?.name||""}
                  </Typography>
                  <div className="h-[1px] w-5 rotate-[105deg] bg-black" />
                </>
              ) : (
                <Typography
                  className="text-xs sm:!text-base capitalize"
                  fontWeight={theme.typography.fontWeightRegular}
                  variant="h6"
                >
                  {(data?.name[0].toUpperCase() + data?.name?.slice(1)||"")}
                </Typography>
               
              )}
            </div>
          );
        }):
         BreadCrumbsListing && BreadCrumbsListing.map((breadcrumb,index)=>{
          return  <div key={index} className="flex items-center">
          {index !== BreadCrumbsListing?.length - 1 ? (
            <>
              {" "}
              <Typography
                fontWeight={theme.typography.fontWeightRegular}
                variant="h6"
                className="cursor-pointer text-xs sm:!text-base capitalize"
                color="secondary"
                onClick={() =>{
                  navigate(`/${breadcrumb?.path}`)}
                }
              >
                {breadcrumb?.name || ""}
              </Typography>
              <div className="h-[1px] w-5 rotate-[105deg] bg-black" />
            </>
          ) : (
            <Typography
              className="text-xs sm:!text-base capitalize"
              fontWeight={theme.typography.fontWeightRegular}
              variant="h6"
            >
              {breadcrumb?.name || ""}
            </Typography>
          )}
        </div>
         })
        }

        
    </div>
  );
};

export default BreadCrumb;
