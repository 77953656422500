import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
// import { Button } from "@mui/material";
import {  CloseIcon } from "../../assets/common-svg/CommonSvg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400, md: 500, lg: 400 },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  py: "15px",
};
// const timerWrapperStyle = {
//   position: "relative",
//   marginTop: "20px",
//   textAlign: "center",
//   margin: "auto",
//   width: "fit-content",
// };
const radius = 50; 
const stroke = 9; 
const circumference = 2 * Math.PI * radius;

export default function UserActiveModal({
  open,
  setOpen,
  // radius,
  // stroke,
  // circumference,
  // offset,
  timer,
  // setTimer,
  handleStillThere
}) {
  const handleClose = () => {
    setOpen(false);
    handleStillThere()
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open || false}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Slide direction="left" in={open} mountOnEnter unmountOnExit>
            <div className="relative h-full w-full p-4">
              <Box sx={style} className="relative p-4">
                <div className="pb-[10px] border-b border-[#DDDDDD]">
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    textAlign={"center"}
                    component="h6"
                    className="text-2xl"
                  >
                    Inactivity Detected
                  </Typography>
                </div>
                <div
                  onClick={handleClose}
                  className=" absolute right-[-30px] top-[-30px] cursor-pointer"
                >
                    <CloseIcon />
                </div>
                <h3 className="text-center">You are about to be logged out!</h3>
                <div className="position-relative text-center m-auto w-fit mt-20">
                    <svg width="120" height="120" viewBox="0 0 120 120">
                      <circle
                        cx="60"
                        cy="60"
                        r={radius}
                        stroke="#ccc"
                        strokeWidth={stroke}
                        fill="none"
                      />
                      <circle
                        cx="60"
                        cy="60"
                        r={radius}
                        stroke="#FB9D2C"
                        strokeWidth={stroke}
                        fill="none"
                        strokeDasharray={circumference}
                        // strokeDashoffset={offset}
                        style={{
                          transition: "stroke-dashoffset 1s linear",
                        }}
                      />
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dy=".3em"
                        fontSize="19"
                        fill="#000000"
                      >
                        {timer}s
                      </text>
                    </svg>
                    {/* <CircleTimerText radius={radius} stroke={stroke} circumference={circumference} offset={offset} timer={timer}/> */}
                  </div>
                <div>
                 
                  <div className="py-2">
                        <p className="bg-[#FFEDCB] text-[#FB9D2C] p-2 rounded">
                          <span className="font-medium">Alert : </span>
                          <span className="text-xs"> It seems like you have been inactive for a while. your
                          session will automatically expire in  {timer}s</span>
                        </p>
                      </div>
                  <p className="text-black-50">
                   
                  </p>
               
                </div>
                {/* <div className="d-flex justify-center">
                  <Button variant="text" onClick={handleClose}>
                    Close
                  </Button>
                </div> */}
              </Box>
            </div>
          </Slide>
        </Fade>
      </Modal>
    </div>
  );
}

UserActiveModal.proptypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
