import httpService from "./httpService";

const PhaseApi = {
  getPhaseListData: (limit, page, search, product_id) =>
    httpService.get("phaseList", {
      params: {
        ...(limit ? { limit } : {}),
        page,
        searchData: search,
        product_id,
      },
    }),
  getPhaseList: (
    productId = "",
    searchData = "",
    limit,
    page,
    userId = "",
    roleId = ""
  ) =>
    httpService.get(
      `phaseList?product_id=${productId}&searchData=${searchData}&limit=${limit}&page=${page}&user_id=${userId}&roleid=${roleId}`
    ),
  getPhaseListDetails: (id,pi_id) => httpService.get(`phaseDetails/${id}?user_id=${pi_id||""}`),
  getPhaseTypeDropDown: () => httpService.get(`phaseTypeDropDown`),
  addPhase: (formData) => httpService.post(`addPhase`, formData),
  updatePhase: (formData, id) =>
    httpService.post(`updatePhase/${id}`, formData),
  deletePhase: (id) => httpService.delete(`deletePhase/${id}`),
  exportFormCsv: (id, questions) => httpService.post(`get_reports_new/${id}`,   questions),
  getQuestionsByFormTemplate: (phaseId, id) => httpService.post(`getQuestionsByFormTemplate`, {"form_ids": id}),

  getTemplateTypeList:(
    page=1,
    limit=5,
    searchData="",
  )=>httpService.get(`templateTypeList?searchData=${searchData}&limit=${limit}&page=${page}`)
};

export default PhaseApi;
