// Admin Module

import { TemplateStatus } from "./common";

//Table Data for PHASE-DETAILS

export const phaseDetailsData = (alignment, role_id) => {
  const rows = [];
  const columns = [
    "S.No",
    "Patient Id",
    "Patient Name",
    "Patient Status",
    "Last Recorded Visit",
    "Appointment Completed",
    "Editor",
    "Cycle No.",
    "Gender",
    "Last Appointment",
  ];
  const columnAlign =
    alignment === "Subject"
      ? [
          { rowAlign: "left", colAlign: "left", multi: false, show: true },
          { rowAlign: "left", colAlign: "left", multi: false, show: true },
          {
            rowAlign: "left",
            colAlign: "left",
            multi: true,
            multiIndexColumn: "Gender",
            show: true,
          },
          { rowAlign: "left", colAlign: "left", multi: false, show: true },
          {
            rowAlign: "left",
            colAlign: "left",
            multi: true,
            show: true,
            multiIndexColumn: "Last Appointment",
          },
          { rowAlign: "left", colAlign: "left", multi: false, show: true },
          {
            rowAlign: "left",
            colAlign: "left",
            multi: false,
            show: true,
          },
          {
            rowAlign: "center",
            colAlign: "center",
            multi: false,
            show: true,
          },
        ]
      : [
          { rowAlign: "left", colAlign: "left", multi: false, show: true },
          {
            rowAlign: "left",
            colAlign: "left",
            multi: true,
            multiIndexColumn: "Gender",
            show: true,
          },
          { rowAlign: "left", colAlign: "left", multi: false, show: true },
          {
            rowAlign: "left",
            colAlign: "left",
            multi: false,
            show: true,
            custom: "CustomChip",
          },
          { rowAlign: "center", colAlign: "center", multi: false, show: true },
        ];
  let TableSearchActions = {
    tableHeading: "Patients",
    Toggle: false,
    filters:
      alignment === "Subject"
        ? [
            {
              key: "search",
              class: "col-start-1 col-end-3",
              placeholder: "Search by Name",
            },
          ]
        : [
            {
              key: "search",
              class: "col-start-1 col-end-3",
              placeholder: "Search by Name",
            },
            {
              key: "select",
              id: "selectStatus",
              class: "col-start-9 col-end-13",
              placeholder: "Select Status",
            },
          ],
  };

  if (alignment === "Subject") {
    TableSearchActions = {
      ...TableSearchActions,
      actions: [
        { key: "view", module: "subject", auth: 17 },
        { key: "add", module: "appointmentManager", auth: 122 },
      ],
    };
  }

  if (alignment === "Request") {
    columns[3] = "Status";
  }

  if (role_id === 2) {
    TableSearchActions.filters = [
      {
        key: "search",
        class: "col-start-1 col-end-3",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        id: "selectSubject",
        class: "col-start-10 col-end-13",
        placeholder: "Select Editor",
      },
    ];
    TableSearchActions = {
      ...TableSearchActions,
      actions: [{ key: "view", module: "subject", auth: 17 }],
    };
  }

  return { rows, columns, columnAlign, TableSearchActions };
};

// Table Data for PRODUCT-DETAILS
export const productDetailsData = {
  rows: [],
  columns: [
    "S.No",
    "Patient Id",
    "Patient Name",
    "Patient Status",
    "Last Recorded Visit",
    "Investigator",
    "Editor",
    "Cycle No.",
    "Gender",
    "Hospital",
    "Last Appointment",
  ],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: false,
      show: true,
      tooltipBool: true,
      toolAuth: 160,
      toolText: "Click here to edit patient id",
    },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Last Appointment",
      show: true,
    },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Hospital",
      show: true,
    },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
  ],
  TableSearchActions: {
    tableHeading: "Patients",
    button: "Add Patient",
    buttonAuth: { module: "subject", auth: 16 },
    filters: [
      {
        key: "search",
        class: "col-start-1 col-end-6 2xl:col-end-4",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        id: "editorList",
        class: "col-start-7 col-end-10",
        placeholder: "Select Editor",
      },
      {
        key: "select",
        id: "selectPI",
        class: "col-start-10 col-end-13",
        placeholder: "Select PI",
      },
    ],
    actions: [
      { key: "view", module: "subject", auth: 17 },
      { key: "swap", module: "subject", auth: 20 },
      { key: "edit", module: "subject_pending", auth: 161 },
    ],
  },
};

// Table Data for Hospital-Management
export const hospitalManagementData = {
  rows: [],
  columns: ["Id", "Hospital Name", "State", "Address"],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      show: true,
    },
  ],
  TableData: {
    route: "Hospital",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-3 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add New Hospital",
        module: "hospitalManage",
        auth: 33,
      },
    ],
    actions: [
      { key: "edit", module: "hospitalManage", auth: 34 },
      { key: "delete", module: "hospitalManage", auth: 35 },
    ],
  },
};

// Table Data for Principle-Investigator
export const principleInvestigatortData = {
  rows: [],
  columns: [
    "Principal Investigator",
    "Hospital",
    "Assigned Phases",
    "Assigned Editors",
    "Assigned Patients",
    "Email",
    "City",
    "Id",
  ],
  columnAlign: [
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Email",
      show: true,
    },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "City",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
  ],
  TableData: {
    route: "PrincipleInvestigator",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        inputBgColor: "#FFFFFF",
        dropDownBgColor: "#FFFFFF",
        placeholder: "Select Hospital",
        data: [],
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add Principal Investigator",
        module: "principalInvestigator",
        auth: 41,
      },
    ],
    actions: [
      { key: "view", module: "principalInvestigator", auth: 44 },
      { key: "edit", module: "principalInvestigator", auth: 42 },
      { key: "delete", module: "principalInvestigator", auth: 43 },
    ],
  },
};

// Table Data for Principle-Investigator-Detail
export const principleInvestigatortDetailData = {
  rows: [],

  columns: [
    "S.No",
    "Patient Id",
    "Patient Name",
    "Patient Status",
    "Last Recorded Visit",
    "Editor",
    "Cycle No.",
    "Gender",
    "Last Appointment",
    "Sub Id",
  ],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Last Appointment",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
  ],
  TableData: {
    tableHeading: "Patients",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4",
        placeholder: "Search by Name / Id",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        placeholder: "Select Editor",
        id: "editorList",
      },
    ],
    actions: [{ key: "view", module: "subject", auth: 17 }],
  },
};

// Table Data for Form-Template
export const formTemplateData = {
  rows: [],
  columns: ["Template Name", "Type", "Created Date", "Status"],
  columnAlign: [
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Hospital",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
  ],
  TableData: {
    // tableHeading: "Subject",
    route: "FormTemplate",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        inputBgColor: "#FFFFFF",
        dropDownBgColor: "#FFFFFF",
        placeholder: "Select Status",
        name: "status",
        MenuList: TemplateStatus,
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add New Form",
        module: "form",
        auth: 2,
      },
    ],
    actions: [
      { key: "view", module: "form", auth: 7 },
      { key: "edit", module: "form", auth: 3 },
      { key: "clone", module: "form", auth: 5 },
      { key: "delete", module: "form", auth: 4 },
    ],
  },
};

// Table Data for User-Management
export const userManagementData = {
  rows: [],
  columns: ["Name", "Email", "Type", "Assigned PI", "Assigned Phases"],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "center",
      colAlign: "center",
      multi: false,
      show: true,
      isCloumnClick: true,
      color: "#3571FF",
      textDecoration: "underline",
    },
  ],
  TableData: {
    route: "userManagement",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        placeholder: "Select Type",
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add User",
        module: "userManagement",
        auth: 50,
      },
    ],
    actions: [
      { key: "view", module: "userManagement", auth: 53 },
      { key: "edit", module: "userManagement", auth: 51 },
      { key: "delete", module: "userManagement", auth: 52 },
    ],
  },
};

// Table Data for Clinical-Phase-Manager
export const clinicalManagerData = {
  rows: [],
  columns: ["Name", "Email", "Assigned Phases"],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
  ],
  TableData: {
    route: "ClinicalPhaseManager",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add Clinical Phase Manager",
        module: "trialManager",
        auth: 104,
      },
    ],
    actions: [
      { key: "view", module: "trialManager", auth: 106 },
      { key: "edit", module: "trialManager", auth: 105 },
      { key: "delete", module: "trialManager", auth: 107 },
    ],
  },
};

// Table Data for ImmunAct-QA
export const QualityAssociateData = {
  rows: [],
  columns: ["Name", "Email", "Assigned Phases"],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
  ],
  TableData: {
    // tableHeading: "Subject",
    route: "QualityAssociate",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add Quality Associate",
        module: "imunoactQAManager",
        auth: 110,
      },
    ],
    actions: [
      { key: "view", module: "imunoactQAManager", auth: 112 },
      { key: "edit", module: "imunoactQAManager", auth: 111 },
      { key: "delete", module: "imunoactQAManager", auth: 113 },
    ],
  },
};

// Table Data for User-Management-Detail
export const userManagementDetailData = {
  rows: [],
  columns: [
    "S.No",
    "Patient Id",
    "Patient Name",
    "Patient Status",
    "Last Recorded Visit",
    "Principal Investigator",
    "Editor",
    "Cycle No.",
    "Gender",
    "Last Appointment",
    "Sub Id",
  ],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Last Appointment",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
  ],
  TableData: {
    tableHeading: "Patients",
    filters: [
      {
        key: "search",
        class: "col-start-1 col-end-6 2xl:col-end-4",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        placeholder: "Select Principal Investigator",
        id: "selectPI",
      },
      {
        key: "select",
        class: "col-start-10  col-end-13",
        placeholder: " Select Editor",
        id: "editorList",
      },
    ],
    actions: [{ key: "view", module: "subject", auth: 17 }],
  },
};

// Table Data for Editor-Management
export const editorManagementData = {
  rows: [],

  columns: [
    "Id",
    "Editor",
    "Phone Number",
    "Principal Investigator",
    "Phase",
    "Email",
    "Hospital",
  ],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Email",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Hospital",
      show: true,
    },
    {
      rowAlign: "center",
      colAlign: "center",
      multi: false,
      show: true,
      isCloumnClick: true,
      color: "#3571FF",
      textDecoration: "underline",
    },
  ],
  TableData: {
    route: "EditorManagement",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        inputBgColor: "#FFFFFF",
        dropDownBgColor: "#FFFFFF",
        placeholder: "Select Principal Investigator",
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add Editor",
        module: "editorManage",
        auth: 59,
      },
    ],
    actions: [
      { key: "view", module: "editorManage", auth: 62 },
      { key: "edit", module: "editorManage", auth: 60 },
      { key: "delete", module: "editorManage", auth: 61 },
    ],
  },
};

// Table Data for Editor-Management-Detail
export const editorManagementDetailData = {
  rows: [],

  columns: [
    "S.No",
    "Patient Id",
    "Patient Name",
    "Patient Status",
    "Last Recorded Visit",
    "Phase",
    "Appointment Completed",
    "Cycle No.",
    "Gender",
    "Last Appointment",
    "Sub Id",
  ],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      show: true,
      multiIndexColumn: "Last Appointment",
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
  ],
  TableData: {
    tableHeading: "Patients",
    route: "PrincipleInvestigator",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4",
        inputBgColor: "#FFFFFF",
        paddingLeft: "pl-2",
        paddingRight: "pr-2",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        placeholder: "Select Phase",
      },
    ],
    actions: [{ key: "view", module: "subject", auth: 17 }],
  },
};

// Table Data for Disease-Management
export const diseaseManagementData = {
  rows: [],
  columns: ["Id", "Disease Name", "Description"],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: false,
      show: true,
      isLineBreak: true,
    },
  ],
  TableData: {
    route: "DiseaseManagement",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-3 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add New Disease",
        module: "diseaseManage",
        auth: 68,
      },
    ],
    actions: [
      { key: "edit", module: "diseaseManage", auth: 69 },
      { key: "delete", module: "diseaseManage", auth: 70 },
    ],
  },
};
// Table Data for Disease-Management
export const auditHistoryData = {
  rows: [],
  columns: [
    "Action Taken By",
    "Event",
    "Activity",
    "Date & Time",
    "User Type",
    "Item",
    "email",
  ],
  columnAlign: [
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "email",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: false,
      show: true,
      width: "500px",
    },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
  ],
  TableData: {
    route: "AuditHistory",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-3 ",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by User Name",
      },
      {
        key: "date",
        class: "col-start-7  col-end-10 hide-label",
        placeholder: "Select Date",
        minDate: "",
        maxDate: "",
        htmlFor: "date-audio-history",
      },
    ],
  },
};
// Editor Module
// Table Data for editor-Detail
export const editorDetailsData = {
  rowsSubject: [],

  columnsSubject: [
    "Id",
    "Patient Name",
    "Investigator",
    "Editor",
    "Gender",
    "Hospital",
  ],

  rowsRequest: [],

  columnsRequest: ["Id", "Name", "Appointment Completed", "Gender", "Status"],
  SubjectColumnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Hospital",
      show: true,
    },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
    { rowAlign: "center", colAlign: "center", multi: false, show: false },
    { rowAlign: "center", colAlign: "center", multi: false, show: false },
  ],
  RequestColumnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Hospital",
      show: true,
    },
    { rowAlign: "center", colAlign: "center", multi: false, show: false },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
  ],
  SubjectTableData: {
    tableHeading: "Patient",
    Toggle: false,
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-3",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-10  col-end-13",
        placeholder: "All Patients",
      },
    ],
    actions: [{ key: "view", module: "subject", auth: 17 }],
  },
  RequestTableData: {
    tableHeading: "Requests",
    Toggle: false,
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-3",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-10  col-end-13",
        placeholder: "Select Status",
      },
    ],
  },
};

//  Coordinating PI Module
// Table Data for Trials-Detail
export const trialsDetailsData = {
  rows: [],

  columns: [
    "Id",
    "Patient Name",
    "Investigator",
    "Editor",
    "Gender",
    "Hospital",
  ],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Hospital",
      show: true,
    },
    { rowAlign: "center", colAlign: "center", multi: false, show: true },
    { rowAlign: "center", colAlign: "center", multi: false, show: false },
    { rowAlign: "center", colAlign: "center", multi: false, show: false },
  ],
  TableData: {
    tableHeading: "Patient",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-4",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        placeholder: "Select Editor",
      },
      {
        key: "select",
        class: "col-start-10  col-end-13",
        placeholder: "Select Pi",
      },
    ],
    actions: [{ key: "view", module: "subject", auth: 17 }],
  },
};

// Table Data for Editor-Listing
export const editorListingData = {
  rows: [],
  columns: ["Id", "Editor", "Phone Number", "Phase", "Email", "Product", "pid"],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Email",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "center",
      colAlign: "center",
      show: true,
      isCloumnClick: true,
      color: "#3571FF",
      textDecoration: "underline",
    },
    { rowAlign: "center", colAlign: "center", multi: false, show: false },
  ],
  TableData: {
    route: "EditorListing",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-3",
        inputBgColor: "#FFFFFF",
        placeholder: "Search by Name",
      },
      {
        key: "select",
        class: "col-start-7  col-end-10",
        inputBgColor: "#FFFFFF",
        dropDownBgColor: "#FFFFFF",
        placeholder: "Select Phase",
      },
    ],
    actions: [
      { key: "view", module: "editorListing", auth: 22 },
      { key: "add", module: "editorListing", auth: 24 },
    ],
  },
};

// Table Data for Editor-Listing-Detail
export const editorListingDetailData = {
  rows: [],

  columns: [
    "S.No",
    "Patient Id",
    "Patient Name",
    "Patient Status",
    "Last Recorded Visit",
    "Phase",
    "Appointment Completed",
    "Cycle No.",
    "Gender",
    "Last Appointment",
    "Id",
  ],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      show: true,
      multiIndexColumn: "Last Appointment",
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "center", colAlign: "center", multi: false, show: false },
  ],
  TableData: {
    tableHeading: "Patient",
    route: "PrincipleInvestigator",
    filters: [
      {
        key: "search",
        class: "col-start-1  col-end-3",
        inputBgColor: "#FFFFFF",
        paddingLeft: "pl-2",
        paddingRight: "pr-2",
        placeholder: "Search by Name",
      },
      // @info:- This dropdown is filter on basis of phases, currently hide, but may be used in future
    ],
    actions: [{ key: "view", module: "subject", auth: 17 }],
  },
};

// Table Data for Subject-Detail
export const subjectData = (alignment, rowsData) => {
  const rows = rowsData || [];

  const columns = [
    "S.No",
    "Patient Id",
    "Patient Name",
    "Patient Status",
    "Last Recorded Visit",
    "Editor Name",
    "Phase",
    "Cycle No.",
    "Status",
    "Gender",
    "Product",
    "Last Appointment",
  ];

  const columnAlign = [
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      multiIndexColumn: "Gender",
      show: true,
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: true,
      show: true,
      multiIndexColumn: "Last Appointment",
    },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "center",
      colAlign: "center",
      multi: true,
      multiIndexColumn: "Product",
      show: true,
    },
    {
      rowAlign: "center",
      colAlign: "center",
      multi: false,
      // multiIndexColumn: "Product",
      show: true,
    },
  ];
  if (alignment === "Request") {
    columnAlign.push({
      rowAlign: "center",
      colAlign: "center",
      multi: false,
      show: true, // Since alignment is "Request", show should be true
    });
  }

  const filters = [
    {
      key: "search",
      class: "col-start-1 col-end-4",
      inputBgColor: "#FFFFFF",
      placeholder: "Search by Name",
    },
    {
      key: "select",
      class: "col-start-7 col-end-10",
      inputBgColor: "#FFFFFF",
      dropDownBgColor: "#FFFFFF",
      placeholder: "Select Editor",
      id: "editorList",
    },
    {
      key: "select",
      class: "col-start-10 col-end-13",
      inputBgColor: "#FFFFFF",
      dropDownBgColor: "#FFFFFF",
      placeholder: "Select Phase",
      id: "trialList",
    },
  ];

  if (alignment === "Request") {
    filters.push({
      key: "select",
      class: "col-start-13 col-end-16",
      inputBgColor: "#FFFFFF",
      dropDownBgColor: "#FFFFFF",
      placeholder: "Select Status",
      id: "StatusList",
    });
  }

  const TableData = {
    route: "Subject",
    Toggle: false,
    filters: filters,
    actions: [
      { key: "view", module: "subject", auth: 17 },
      { key: "swap", module: "subject", auth: 20 },
    ],
  };

  return { rows, columns, columnAlign, TableData };
};

// Table schema data for add form types
export const FormTypeManagementTableData = {
  rows: [],
  columns: ["Id", "Acronym", "Form Type Name"],
  columnAlign: [
    { rowAlign: "left", colAlign: "left", multi: false, show: false },
    { rowAlign: "left", colAlign: "left", multi: false, show: true },
    {
      rowAlign: "left",
      colAlign: "left",
      multi: false,
      show: true,
      isLineBreak: true,
    },
  ],
  TableData: {
    route: "formTypeManagement",
    filters: [
      {
        key: "button",
        class: "col-start-10  col-end-13",
        placeholder: "Add New Form Type",
        module: "formTypeManage",
        auth: 148,
      },
    ],
    actions: [
      { key: "edit", module: "formTypeManage", auth: 149 },
      { key: "delete", module: "formTypeManage", auth: 150 },
    ],
  },
};
