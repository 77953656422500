import React, { useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import { useDrag, useDrop } from "react-dnd";
import {
  AddICon,
  CloseIcon,
  DeleteIcon,
  DocumentIcon,
  Survey,
  UpDownArrowIcon,
} from "../../assets/common-svg/CommonSvg";
import { Button, List, ListItem, ListItemIcon } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../custom-input/CustomInput";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteConfirmContent from "../modal-contents/DeleteConfirmModalContent";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/reducers/snackBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400, md: 500, lg: 400 },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  py: "15px",
  maxHeight: {
    xs: "95vh",
    sm: "95vh",
    md: "95vh",
    lg: "90vh",
    xl: "95vh",
  },
};

const ItemType = "FORM_ITEM";

export default function CustomPhaseModal({
  open,
  setOpen,
  modelData,
  handleModelValues,
  setModelValues,
  handleVisitFrequency,
  setSelectFormType,
  selectFormType,
  setSecondPhaseModal,
  maxPages,
  page,
  setPage,
  loadMoreData,
  setIsAddPhaseLoading,
  setIsTrialInfoLoading,
  count,
  setCount
}) {

  const formRef = useRef();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const [formTypeId, setFormTypeId] = useState("");
  const [initialValues, setInitialValues] = useState({
    template_type_array: {},
  });

  const handleClose = () => {
    setPage(1);
    setOpen(false);
    setSelectFormType([]);
    setModelValues([]);
      setInitialValues({
      template_type_array: {},
    });
    setFormTypeId("");
    setIsAddPhaseLoading(false);
    setIsTrialInfoLoading(false);
    setCount(1)
  };
  

  const handleDeleteConfirmation = () => {
    setOpenDelete(true);
  };

  const handleSubmitEvent = () => {
    if (selectFormType.length === 0) {
      dispatch(
        setSnackbar({
          value: true,
          message: "Please select at least one form before submitting.",
          color: "error",
        })
      );
      return;
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
    const templateTypes = formRef.current.values?.template_type_array || {};
    const hasEmptyValue = Object.values(templateTypes).some(
      (item) =>
        item?.value === undefined || item?.value === null || item?.value === "" || item?.value === 0 ||item?.value > 100
    );

    if (hasEmptyValue) {
      dispatch(
        setSnackbar({
          value: true,
          message: "Please enter a valid visit frequency number (between 1 and 100).",
          color: "error",
        })
      );
      return;
    }

    setSelectFormType([]);
    setOpen(false);
    setSecondPhaseModal(true);
  };

  const handleClick = (id) => {
    const selectedForm = handleModelValues.find((form) => form.id === id);
    setSelectFormType([...selectFormType, selectedForm]);
    const updatedChooseFormType = handleModelValues.filter(
      (form) => form.id !== id
    );
    setModelValues(updatedChooseFormType);
    setInitialValues((prevValues) => ({
      ...prevValues,
      template_type_array: {
        ...prevValues.template_type_array,
        [`${id}`]: {
          value: 1,
          order: count,
        },
      },
    }));

    setCount((prevCount) => prevCount + 1);    
  };

  const handleDelete = (confirmation) => {
    if (confirmation) {
      const formToDelete = selectFormType.find((form) => form.id === formTypeId);
  
      if (formToDelete) {
        const updatedSelectFormType = selectFormType.filter(
          (form) => form.id !== formTypeId
        );
  
        setSelectFormType(updatedSelectFormType);
  
        if (!handleModelValues.some((form) => form.id === formToDelete.id)) {
          setModelValues([...handleModelValues, formToDelete]);
        }
      }
        setFormTypeId("");
      setOpenDelete(false);
  
      setInitialValues((prevValues) => {
        const newValues = { ...prevValues };
        delete newValues.template_type_array[formTypeId];
        return newValues;
      });
    } else {
      setFormTypeId("");
      setOpenDelete(false);
    }
  };
  


  const moveFormItem = (draggedId, targetId) => {
    const draggedItem = selectFormType.find((form) => form.id === draggedId);
    const targetIndex = selectFormType.findIndex(
      (form) => form.id === targetId
    );
    const updatedFormType = [...selectFormType];
    const draggedIndex = updatedFormType.findIndex(
      (form) => form.id === draggedId
    );
    updatedFormType.splice(draggedIndex, 1);
    updatedFormType.splice(targetIndex, 0, draggedItem);
    setSelectFormType(updatedFormType);
  };

  const validationSchema = Yup.object().shape({
    template_type_array: Yup.object().shape(
      Object.fromEntries(
        Object.keys(initialValues.template_type_array || {}).map((key) => [
          key,
          Yup.object().shape({
            value: Yup.number()
              .required("Visit Frequency is required")
              .min(1, "Visit Frequency must be at least 1")
              .max(100, "The maximum number of Visit Frequency is 100"),
          }),
        ])
      )
    ),
  });
  const dragRef = useRef(null);

  const DraggableListItem = ({
    res,
    index,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    values,
  }) => {
    const [, drag] = useDrag({
      type: ItemType,
      item: { id: res?.id, index },
    });

       const [, drop] = useDrop({
      accept: ItemType,
      hover: (item, monitor) => {
        if (!dragRef.current) return;
  
        const clientOffset = monitor.getClientOffset();
        const container = dragRef?.current?.closest(".overflow-auto");
        if (!container || !clientOffset) return;
  
        const { top, bottom } = container.getBoundingClientRect();
        const SCROLL_THRESHOLD = 50;
        
        if (clientOffset.y < top + SCROLL_THRESHOLD) {
          container.scrollBy({ top: -15, behavior: "smooth" });
        } else if (clientOffset.y > bottom - SCROLL_THRESHOLD) {
          container.scrollBy({ top: 15, behavior: "smooth" });
        }
  

        if (item.index !== index) {
          moveFormItem(item.id, res?.id);
          item.index = index;
        }
      },
    });

    useEffect(() => {
      setFieldValue(`template_type_array['${res.id}'][order]`, index + 1);
    }, [index]);

    return (
      <ListItem
        className="border-b border-gray-300 justify-between items-center cursor-grab group active:cursor-grabbing"
        key={res.id}
        ref={(node) => drag(drop(node))}
      >
        <div className="group-hover:fill-sky-600  mr-4">
          <UpDownArrowIcon />
        </div>
        <div>
          <p className="text-base text-capitalize">{res.name}</p>
          <div className="p-2 modalInner flex flex-col gap-6 mb-4">
            {modelData?.children?.map((child, ind) => {
              if (child?.type === "number") {
                return (
                  <div key={ind}>
                    <input
                      type="hidden"
                      name={`template_type_array[${res.id}].order`}
                      value={values?.template_type_array[res.id]?.order}
                    />
                    <CustomInputField
                      key={res.id}
                      htmlFor={String(res.id)}
                      label={child?.label}
                      IsRequired={child?.IsRequired}
                      fullWidthBoolean={true}
                      InputId={String(res.id)}
                      InputType="number"
                      minValueNumber={child?.minValueNumber || 1}
                      spacing={1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputName={`template_type_array[${res.id}].value`}
                      CustomClassName="bg-[#EDEDED] text-[black] font-normal text-[18px]"
                      placeholder={child?.placeholder}
                      LabelClasses={"text-[16px] text-[#000000]"}
                      error={Boolean(
                        touched.template_type_array?.[res.id]?.value &&
                          errors.template_type_array?.[res.id]?.value
                      )}
                      touched={touched.template_type_array?.[res.id]?.value}
                      helperText={
                        touched.template_type_array?.[res.id]?.value &&
                        errors.template_type_array?.[res.id]?.value
                          ? errors.template_type_array?.[res.id]?.value
                          : ""
                      }
                      value={values.template_type_array?.[res.id]?.value || ""}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div
          className="bg-red-500 rounded"
          onClick={() => {
            handleDeleteConfirmation();
            setFormTypeId(res.id);
          }}
        >
          <ListItemIcon className="flex justify-center items-center cursor-pointer p-1">
            <DeleteIcon filled="#ffffff" />
          </ListItemIcon>
        </div>
      </ListItem>
    );
  };

  return (
    <div>
      <DeleteConfirmContent
        open={openDelete}
        setOpen={setOpenDelete}
        handleDelete={handleDelete}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Slide direction="left" in={open} mountOnEnter unmountOnExit>
            <div className="relative h-full">
              <Box sx={style} className="w-[700px]">
                <div
                  onClick={handleClose}
                  className="absolute right-0 1sm:right-[-30px] top-[-30px] cursor-pointer"
                >
                  <Tooltip title="close">
                    <div>
                      <CloseIcon />
                    </div>
                  </Tooltip>
                </div>
                <div className="flex w-full p-4">
                  {/* Left Section */}
                  <div className="flex-1 pr-3">
                    <div className="pb-2 border-b border-gray-300">
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        textAlign="start"
                        component="h6"
                      >
                        Select Required Visits
                      </Typography>
                    </div>
                    <div
                      id="scrollableDiv"
                      className="overflow-auto max-h-[60vh] pr-1"
                    >
                      <InfiniteScroll
                        dataLength={handleModelValues?.length}
                        next={loadMoreData}
                        hasMore={page <= maxPages }
                        scrollableTarget="scrollableDiv"
                      >
                        <List className="">
                          {handleModelValues?.map((res) => (
                            <ListItem
                              key={res.id}
                              className="flex justify-between items-center p-0 pb-2 pt-2 mb-2 border-b border-gray-300"
                            >
                              <div className="mr-4">
                                <DocumentIcon LightBlue={true} />
                              </div>
                              <div className="flex-1">
                                <p className="text-base text-capitalize">
                                  {res.name}
                                </p>
                              </div>

                              {/* Button at the end */}
                              <Tooltip title="Add Form Type">
                                <button
                                  onClick={() => handleClick(res.id)}
                                  >
                                  <div>
                                  <AddICon/>
                                  </div>
                                </button>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>
                      </InfiniteScroll>
                    </div>
                  </div>

                  {/* Right Section */}
                  <div className="flex-1">
                    <div className="pb-2 border-b border-gray-300">
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        textAlign="start"
                        component="h6"
                      >
                        Configure Visit Frequency & Order
                      </Typography>
                    </div>
                    <div className="max-h-[60vh] overflow-auto" ref={dragRef}>
                      <div className="py-2">
                        <p className="bg-[#FFEDCB] text-[#FB9D2C] p-2 rounded">
                          <span className="font-medium">Note : </span>
                          <span className="text-xs">Drag & Drop Forms To Arrange Visit Order</span>
                        </p>
                      </div>
                      <Formik
                        innerRef={formRef}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        validateOnMount={true}
                        onSubmit={(values) => {
                          handleVisitFrequency(values);
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          handleSubmit,
                          touched,
                          values,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-7"
                          >
                            {selectFormType?.length > 0 ? (
                              <List className="bg-gray-100 h-100">
                                {selectFormType?.map((res, index) => (
                                  <DraggableListItem
                                    key={res.id}
                                    res={res}
                                    index={index}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                  />
                                ))}
                              </List>
                            ) : (
                              <div className="bg-gray-100 flex flex-col items-center justify-center text-center h-full p-5">
                                <Survey />
                                <p className="text-xs">
                                  There is no form type available. Click Add to
                                  include one.
                                </p>
                              </div>
                            )}
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>

                {/* Button at start */}
                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    onClick={handleSubmitEvent}
                    className="justify-center w-25 m-1"
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </div>
          </Slide>
        </Fade>
      </Modal>
    </div>
  );
}
